import initialState from './initialState';
import {
    PASSWORD_INPUT_CHANGE,
    COMFIRM_PASSWORD_INPUT_CHANGE,
    PASSWORD_CHANGE_SUBMIT,
    PASSWORD_CHANGE_SUCCESS,
    PASSWORD_CHANGE_ERROR,
    PASSWORD_RESET_ERRORS,
    PASSWORD_RESET_REQUEST_SUBMIT,
    PASSWORD_RESET_REQUEST_SUCCESS,
    PASSWORD_RESET_REQUEST_ERROR,
    VERIFY_PREFLIGHT_REQUEST,
    VERIFY_PREFLIGHT_RESPONSE,
    VERIFY_PREFLIGHT_ERROR
} from '../actions/actionTypes';

export default function password(state = initialState.password, action) {

    switch (action.type) {
        case PASSWORD_INPUT_CHANGE:
            return Object.assign(
                {},
                state,
                {
                    strength: action.strength,
                    match: action.match,
                    warning: action.warning,
                    suggestions: action.suggestions
                }
            )
        case COMFIRM_PASSWORD_INPUT_CHANGE:
            return Object.assign(
                {},
                state,
                {
                    match: action.match
                }
            )
        case PASSWORD_CHANGE_SUBMIT:
            return Object.assign(
                {},
                state,
                {
                    loading: true,
                    errors: {}
                }
            )
        case PASSWORD_CHANGE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    errors: {},
                    loading: false,
                    success: true,
                    api: action.api
                }
            )
        case PASSWORD_CHANGE_ERROR:
            return Object.assign(
                {},
                state,
                {
                    errors: action.errors,
                    loading: false,
                    success: false
                }
            )
        case PASSWORD_RESET_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    errors: {},
                    loading: false,
                    match: false,
                    success: false
                }
            )
        case PASSWORD_RESET_REQUEST_SUBMIT:
            return Object.assign(
                {},
                state,
                {
                    errors: {},
                    loading: true,
                    success: false
                }
            )
        case PASSWORD_RESET_REQUEST_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    errors: {},
                    loading: false,
                    success: true
                }
            )
        case PASSWORD_RESET_REQUEST_ERROR:
            return Object.assign(
                {},
                state,
                {
                    errors: action.errors,
                    loading: false,
                    success: false
                }
            )
        case VERIFY_PREFLIGHT_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    errors: {},
                    loading: true,
                    mfaRequirement: null
                }
            )
        case VERIFY_PREFLIGHT_RESPONSE:
            return Object.assign(
                {},
                state,
                {
                    errors: {},
                    loading: false,
                    mfaRequirement: action.data['client_mfa_requirement'],
                    enrolled: action.data['enrolled'],
                    api: action.data['api']

                }
            )
        case VERIFY_PREFLIGHT_ERROR:
            return Object.assign(
                {},
                state,
                {
                    errors: action.errors,
                    loading: false,
                }
            )
        default:
            return state
    }
}
