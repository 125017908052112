import moment from 'moment'
import initialState from './initialState';
import {
    LOGIN_REQUEST,
    LOGIN_RESPONSE,
    LOGIN_ERRORS,
    LOGIN_SUCCESS,
    LOGIN_RESET_ERRORS,
    SEND_MFA_TOKEN_REQUEST,
    SEND_MFA_TOKEN_RESPONSE,
    SEND_MFA_TOKEN_ERROR,
    VERIFY_MFA_TOKEN_REQUEST,
    VERIFY_MFA_TOKEN_RESPONSE,
    VERIFY_MFA_TOKEN_ERROR,
    REFRESH_ACCESS_TOKEN_REQUEST,
    REFRESH_ACCESS_TOKEN_RESPONSE,
    REFRESH_ACCESS_TOKEN_ERROR,
    LOGOUT_RESPONSE,
    LOGGED_IN_USER_DETAILS_REQUEST,
    LOGGED_IN_USED_DETAILS_RESPONSE,
    NOT_AUTHORISED_RESPONSE,
    CHECK_USER_LOCKDOWN_STATUS_REQUEST,
    CHECK_USER_LOCKDOWN_STATUS_RESPONSE,
    FORCED_OUT,
    INHERIT_AUTH_CREDENTIALS,
    RESET_LOGIN_COUNTER,
    TOTP_CREATE_FACTOR_REQUEST,
    TOTP_CREATE_FACTOR_RESPONSE_SUCCESS,
    TOTP_CREATE_FACTOR_RESPONSE_ERRORS,
    TOTP_VERIFY_FACTOR_REQUEST,
    TOTP_VERIFY_FACTOR_RESPONSE_SUCCESS,
    TOTP_VERIFY_FACTOR_RESPONSE_ERRORS,
    TOTP_FACTOR_RESET,
    USER_MFA_ENTER,
    USER_MFA_SETUP
} from '../actions/actionTypes';

export default function auth(state = initialState.auth, action) {
    var accessTokenExpiry
    switch (action.type) {
        case LOGIN_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    loading: true,
                    errors: {}
                }
            )
        case LOGIN_RESPONSE:
            return Object.assign(
                {},
                state,
                action.auth,
                {
                    loading: false
                })
        case LOGIN_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    errors: action.auth
                },
                {
                    loading: false,
                    attempts: state.attempts += 1
                },
                {
                    force_out: false
                }
            )
        case LOGIN_SUCCESS:
            accessTokenExpiry = moment().add(action.auth.expires_in, 'seconds').format()
            // Save to local storage
            localStorage.setItem('refreshToken', action.auth.refresh_token)
            localStorage.setItem('accessToken', action.auth.access_token)
            localStorage.setItem('accessTokenExpiry',accessTokenExpiry)
            return Object.assign(
                {},
                state,
                action.auth,
                {
                    loading: false,
                    errors: {},
                    attempts: 0
                },
                {
                    access_token_expiry: accessTokenExpiry
                },
                {
                    force_out: false
                }
            )
        case LOGIN_RESET_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    errors: {}
                },
                {
                    loading: false,
                }
            )
        case SEND_MFA_TOKEN_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    errors: {}
                },
                {
                    loading: true,
                    mfa_number: null

                }
            )
        case SEND_MFA_TOKEN_RESPONSE:
            let number = null
            if ('number' in action.data) {
                number = action.data.number
            }
            return Object.assign(
                {},
                state,
                {
                    errors: {}
                },
                {
                    loading: false,
                    mfa_number: number
                }
            )
        case SEND_MFA_TOKEN_ERROR:
            return Object.assign(
                {},
                state,
                {
                    errors: {}
                },
                {
                    loading: false,
                    errors: action.errors
                }
            )
        case VERIFY_MFA_TOKEN_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    errors: {}
                },
                {
                    loading: true,
                }
            )
        case VERIFY_MFA_TOKEN_RESPONSE:
            return Object.assign(
                {},
                state,
                {
                    errors: {}
                },
                {
                    loading: false,
                }
            )
        case VERIFY_MFA_TOKEN_ERROR:
            return Object.assign(
                {},
                state,
                {
                    errors: {}
                },
                {
                    loading: false,
                    errors: action.errors
                }
            )
        case REFRESH_ACCESS_TOKEN_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    refreshingAccessToken: true
                }
            )
        case REFRESH_ACCESS_TOKEN_RESPONSE:
            accessTokenExpiry = moment().add(action.auth.expires_in, 'seconds').format()
            // Save to local storage
            localStorage.setItem('accessToken', action.auth.access_token)
            localStorage.setItem('accessTokenExpiry',accessTokenExpiry)
            return Object.assign(
                {},
                state,
                action.auth,
                {
                    refreshingAccessToken: false,
                    loading: false,
                    errors: {},
                    attempts: 0
                },
                {
                    access_token_expiry: accessTokenExpiry
                }
            )
        case REFRESH_ACCESS_TOKEN_ERROR:
            return Object.assign(
                {},
                state,
                {
                    refreshingAccessToken: false,
                    loading: false
                }
            )
        case LOGOUT_RESPONSE:
            localStorage.removeItem('refreshToken')
            localStorage.removeItem('accessToken')
            localStorage.removeItem('accessTokenExpiry')
            return Object.assign({}, initialState.auth)
        case LOGGED_IN_USER_DETAILS_REQUEST:
            return state
        case LOGGED_IN_USED_DETAILS_RESPONSE:
            return Object.assign({}, state, { user: action.auth } )
        case NOT_AUTHORISED_RESPONSE:
            return Object.assign(
                {},
                state,
                {
                    force_out: true
                }
            )
        case FORCED_OUT:
            return Object.assign(
                {},
                initialState.auth,
                {
                    force_out: false
                }
            )
        case CHECK_USER_LOCKDOWN_STATUS_REQUEST:
            return state
        case CHECK_USER_LOCKDOWN_STATUS_RESPONSE:
            if (action.status === "ON") {
                // Essentially, removing access tokens etc...
                localStorage.removeItem('refreshToken')
                localStorage.removeItem('accessToken')
                localStorage.removeItem('accessTokenExpiry')
                return Object.assign({}, initialState.auth)
            } else {
                return state
            }
        case INHERIT_AUTH_CREDENTIALS:
            accessTokenExpiry = moment().add(action.auth.expires_in, 'seconds').format()
            // Save to local storage
            localStorage.setItem('accessToken', action.auth.access_token)
            localStorage.setItem('accessTokenExpiry', accessTokenExpiry)
            return Object.assign(
                {},
                state,
                action.auth
            )
        case TOTP_CREATE_FACTOR_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    totp: 'requested'
                }
            )
        case TOTP_CREATE_FACTOR_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    totp: 'created',
                    totpUrl: action.details.qr_code_url
                }
            )
        case TOTP_CREATE_FACTOR_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    errors: {}
                },
                {
                    totp: 'errors',
                    errors: action.error
                }
            )
        case TOTP_VERIFY_FACTOR_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    totp: 'verify-requested'
                }
            )
        case TOTP_VERIFY_FACTOR_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    totp: 'verified',
                    totpUrl: action.details.qr_code_url
                }
            )
        case TOTP_VERIFY_FACTOR_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    errors: action.errors
                },
                {
                    totp: 'errors',
                }
            )
        case RESET_LOGIN_COUNTER:
            return Object.assign(
                {},
                state,
                {
                    attempts: 0
                }
            )
        case TOTP_FACTOR_RESET:
            return Object.assign(
                {},
                state,
                {
                    totp: null
                }
            )
        case USER_MFA_ENTER:
            return Object.assign(
                {},
                state,
                {
                    user_mfa_enter: true
                }
            )
        case USER_MFA_SETUP:
            return Object.assign(
                {},
                state,
                {
                    user_mfa_setup: true
                }
            )
        default:
            return state
    }
}
