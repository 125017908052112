import initialState from './initialState'
import {
    VIEWER_COMPARE_CALENDAR_SET_CURRENT,
    VIEWER_CLEAR_COMPARE_CALENDAR,
    VIEWER_COMPARE_SHOW_COMPARE_CALENDAR_POPOUT,
    VIEWER_COMPARE_HIDE_COMPARE_CALENDAR_POPOUT,
    VIEWER_SELECT_PROJECT_AND_CAMERA,
    LOGIN_RESPONSE
} from '../actions/actionTypes'

export default function compareCalendar(state = initialState.compareCalendar, action) {

    switch (action.type) {
        case VIEWER_COMPARE_CALENDAR_SET_CURRENT:
            return Object.assign(
                {},
                state,
                action.current
            )
        case VIEWER_CLEAR_COMPARE_CALENDAR:
            return initialState.compareCalendar
        case VIEWER_COMPARE_SHOW_COMPARE_CALENDAR_POPOUT:
            return Object.assign(
                {},
                state,
                {popOutVisible: true}
            )
        case VIEWER_COMPARE_HIDE_COMPARE_CALENDAR_POPOUT:
            return Object.assign(
                {},
                state,
                {popOutVisible: false}
            )
        case VIEWER_SELECT_PROJECT_AND_CAMERA:
        case LOGIN_RESPONSE:
            return initialState.compareCalendar
        default:
            return state
    }
}
