import initialState from './initialState'
import {
    VIEWER_PHOTO_SHOW_PAN_WINDOW,
    VIEWER_PHOTO_HIDE_PAN_WINDOW,
    VIEWER_PHOTO_PREVENT_HIDE_PAN_WINDOW,
    VIEWER_PHOTO_ALLOW_HIDE_PAN_WINDOW,
} from '../actions/actionTypes'

export default function panWindow(state = initialState.panWindow, action) {
    switch (action.type) {
        case VIEWER_PHOTO_SHOW_PAN_WINDOW:
            return Object.assign(
                {},
                state,
                {
                    panWindowVisible: true
                }
            )
        case VIEWER_PHOTO_HIDE_PAN_WINDOW:
            if (state.preventHidePanWindow) {
                return state
            }
            return Object.assign(
                {},
                state,
                {
                    panWindowVisible: false
                }
            )
        case VIEWER_PHOTO_PREVENT_HIDE_PAN_WINDOW:
            return Object.assign(
                {},
                state,
                {
                    preventHidePanWindow: true
                }
            )
        case VIEWER_PHOTO_ALLOW_HIDE_PAN_WINDOW:
            return Object.assign(
                {},
                state,
                {
                    preventHidePanWindow: false
                }
            )
        default:
            return state
    }
}
