import initialState from './initialState'
import {
    VIEWER_LOAD_WEATHER_REQUEST,
    VIEWER_LOAD_WEATHER_SUCCESS,
    VIEWER_LOAD_WEATHER_ERROR,
    LOGIN_RESPONSE
} from '../actions/actionTypes'

export default function weather(state = initialState.weather, action) {

    switch (action.type) {
        case VIEWER_LOAD_WEATHER_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    loading: true,
                    errors: {}
                }
            )
        case VIEWER_LOAD_WEATHER_SUCCESS:
            const hours = action.weather.map(_hour => {
                return {
                    summary: _hour.summary,
                    icon: _hour.icon,
                    temperature: Math.round(_hour.temperature),
                    temperatureUnits: _hour.temperatureUnits.replace('&deg;', String.fromCharCode(176)),
                    windSpeed: Math.round(_hour.windSpeed),
                    windSpeedUnits: _hour.windSpeedUnits,
                    windDirection: _hour.windDirection,
                }
            })
            return Object.assign(
                {},
                state,
                {
                    loading: false,
                    success: true,
                    errors: {},
                    hours: hours
                }
            )
        case VIEWER_LOAD_WEATHER_ERROR:
            return Object.assign(
                {},
                state,
                {
                    loading: false,
                    success: false,
                    errors: action.errors,
                    hours: []
                }
            )
        case LOGIN_RESPONSE:
            return initialState.weather
        default:
            return state
    }
}
