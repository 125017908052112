import moment from 'moment'

import * as types from './actionTypes'
import { setAssociatedCamera, setCameraIsModel } from './cameraActions'
import { loadImageList } from './associatedCameraActions'
import { selectImage } from './compareImageActions'

export const COMPARE_WINDOW = 'COMPARE_WINDOW'
export const COMPARE_WIPE = 'COMPARE_WIPE'
export const COMPARE_OVERLAY = 'COMPARE_OVERLAY'
export const COMPARE_DIFFERENCE = 'COMPARE_DIFFERENCE'

export function setComparison(target, date, time) {
    return (dispatch, getState) => {
        const state = getState()
        // Nothing to compare with, disable compare
        if (!target) {
            if (state.compare.enabled) {
                // Trigger Google Analytics disable compare event
                if (window.ga) window.ga('send', 'event', {
                  eventCategory: 'Compare',
                  eventAction: 'Disable compare',
                  eventLabel: 'Disable compare'
                })
                dispatch({
                    type: types.VIEWER_COMPARE_DISABLE
                })
            }
            return
        }
        if (!state.compare.enabled) {
            // Trigger Google Analytics enable compare event
            if (window.ga) window.ga('send', 'event', {
              eventCategory: 'Compare',
              eventAction: 'Enable compare',
              eventLabel: 'Enable compare'
            })
        }
        dispatch({
            type: types.VIEWER_COMPARE_HIDE_NO_BIM_MESSAGE
        })
        const isModel = state.camera.isModel
        let withSelf
        let with3d = false


        switch(target) {
            case '3d':
                let final3dImage = null
                if (state.camera.associatedCamera !== undefined) {
                    const project = state.user.projects[state.navigation.currentProject]
                    if (project) {
                        const [firstAssociatedCamera] = state.camera.associatedCamera
                        let associatedCamera = null
                        if (firstAssociatedCamera) {
                            associatedCamera = project.cameras[firstAssociatedCamera.index]
                        }
                        if (associatedCamera) {
                            final3dImage = associatedCamera.final3dImage
                        }
                    }
                }
                with3d = true
                withSelf = state.compare.withSelf
                // Hide compare calendar
                dispatch({
                    type: types.VIEWER_COMPARE_CALENDAR_HIDE
                })
                // Set image
                if (final3dImage) {
                    dispatch({
                        type: types.VIEWER_SELECT_COMPARE_IMAGE,
                        width: final3dImage.width,
                        height: final3dImage.height,
                        time: null,
                        date: null,
                        image: [],
                        image_url: final3dImage.url,
                        thumbnail_url: null
                    })
                }
                break
            case 'model':
                withSelf = 'isModel'
                dispatch({
                    type: types.VIEWER_COMPARE_CALENDAR_SHOW
                })
                break
            case 'model-contract':
                withSelf = 'isContract'
                dispatch({
                    type: types.VIEWER_COMPARE_CALENDAR_SHOW
                })
                break
            case 'model-delivery':
                withSelf = 'isDelivery'
                dispatch({
                    type: types.VIEWER_COMPARE_CALENDAR_SHOW
                })
                break
            default:
                withSelf = !isModel
                dispatch({
                    type: types.VIEWER_COMPARE_CALENDAR_SHOW
                })
        }

        dispatch({
            type: types.VIEWER_COMPARE_CHANGE_TARGET,
            withSelf: withSelf,
            with3d: with3d
        })
        const previouslyWithSelf = state.compare.withSelf
        const previouslyWith3d = state.compare.with3d
        if (withSelf !== previouslyWithSelf || with3d !== previouslyWith3d) {
            // Trigger Google Analytics change compare target event
            if (window.ga) window.ga('send', 'event', {
              eventCategory: 'Compare',
              eventAction: `Compare with ${target}`,
              eventLabel: 'Change of compared object'
            })
        }
        // If no compare date set, use the same date as the main camera
        if (date === null || date === undefined) {
            date = state.camera.selectedDate
        }
        if (!with3d) {
            dispatch(selectDate(date, time, null))
        }
        dispatch({
            type: types.VIEWER_COMPARE_ENABLE
        })
    }
}

export function enable() {
    return (dispatch, getState) => {
        dispatch({
            type: types.VIEWER_COMPARE_ENABLE
        })
    }
}

export function disable() {
    return dispatch =>  {
        dispatch({
            type: types.VIEWER_CLEAR_COMPARE_IMAGE
        })
        dispatch({
            type: types.VIEWER_COMPARE_DISABLE
        })
    }
}

export function changeMode(mode) {
    return (dispatch, getState) => {
        if (window.ga) window.ga('send', 'event', {
          eventCategory: 'Compare',
          eventAction: `Change mode to ${mode}`,
          eventLabel: 'Change comparison display mode'
        })
        switch(mode) {
            case 'overlay':
                dispatch({
                    type: types.VIEWER_COMPARE_CHANGE_MODE,
                    mode: COMPARE_OVERLAY
                })
                break
            case 'difference':
                dispatch({
                    type: types.VIEWER_COMPARE_CHANGE_MODE,
                    mode: COMPARE_DIFFERENCE
                })
                break
            case 'horizontal':
                dispatch({
                    type: types.VIEWER_COMPARE_CHANGE_WIPE_ORIENTATION,
                    horizontalWipe: true
                })
                dispatch({
                    type: types.VIEWER_COMPARE_CHANGE_MODE,
                    mode: COMPARE_WIPE
                })
                break
            case 'vertical':
                dispatch({
                    type: types.VIEWER_COMPARE_CHANGE_WIPE_ORIENTATION,
                    horizontalWipe: false
                })
                dispatch({
                    type: types.VIEWER_COMPARE_CHANGE_MODE,
                    mode: COMPARE_WIPE
                })
                break
            case 'wipe':
                dispatch({
                    type: types.VIEWER_COMPARE_CHANGE_MODE,
                    mode: COMPARE_WIPE
                })
                break
            case 'window':
                dispatch({
                    type: types.VIEWER_COMPARE_CHANGE_MODE,
                    mode: COMPARE_WINDOW
                })
            default:
                
        }
    }
}

export function setDragging(value) {
    return (dispatch, getState) => {
        dispatch({
            type: types.VIEWER_COMPARE_SET_DRAGGING,
            value: value
        })
    }
}

// export function changeWipeOrientation(orientation) {
//     if (orientation === 'horizontal') {
//         return {
//             type: types.VIEWER_COMPARE_CHANGE_WIPE_ORIENTATION,
//             horizontalWipe: true
//         }
//     } else {
//         return {
//             type: types.VIEWER_COMPARE_CHANGE_WIPE_ORIENTATION,
//             horizontalWipe: false
//         }
//     }
// }

// export function changeOpacity(opacity) {
//     return {
//         type: types.VIEWER_COMPARE_CHANGE_OPACITY,
//         opacity: parseFloat(opacity)
//     }
// }

export function selectDate(date, time) {
    return (dispatch, getState) => {
        if (date === undefined) {
            return
        }
        const state = getState()

        // Final 3D rendered image has no date
        if (state.compare.with3d) {
            return
        }

        let camera
        let cameraIndex
        const currentProjectId = state.navigation.currentProject
        const currentProject = state.user.projects[currentProjectId]
        if (currentProject === undefined) {
            dispatch({ type: types.VIEWER_COMPARE_SELECT_DATE, date: date })
            dispatch({ type: types.VIEWER_COMPARE_SELECT_TIME, time: time })
            return
        }

       if (state.camera.isModel) {
            if (state.compare.withSelf === 'isModel') {
                cameraIndex = state.navigation.currentCamera
            } else {
                cameraIndex = state.camera.associatedCamera
            }
        } else {
            if (state.compare.withSelf === 'isContract') {
                // Is contract
                const contractCamera = state.camera.associatedCamera.find(({type}) => type === 'contract');
                cameraIndex = contractCamera.index
            } else if (state.compare.withSelf === 'isDelivery') {
                // is delivery
                const deliveryCamera = state.camera.associatedCamera.find(({type}) => type === 'delivery');
                cameraIndex = deliveryCamera.index
            } else if (state.compare.withSelf === 'isModel') {
                if (Array.isArray(state.camera.associatedCamera)) {
                    const [firstCamera] = state.camera.associatedCamera
                    cameraIndex = firstCamera.index
                } else {
                    cameraIndex = state.camera.associatedCamera
                }
            } else {
                cameraIndex = state.navigation.currentCamera
            }
        }

        camera = currentProject.cameras[cameraIndex]

        let newDate = moment(date, 'YYYY-MM-DD')
        // Check if date is valid
        const start = moment(camera.start, 'YYYY-MM-DD')
        const end = moment(camera.end, 'YYYY-MM-DD')
        const missing = camera.missing
        if (missing === undefined) {
            // Something has gone wrong, probably the compare camera has no
            // images.
            return
        }

        if (newDate < start || newDate > end || missing.indexOf(date) > -1) {
            if (newDate < start) {
                newDate = start
            } else if (newDate > end) {
                newDate = end
            } else {
                const oldDate = moment(state.compare.selectedDate)
                if (!oldDate.isValid() || oldDate > newDate) {
                    // Find the previous date that isn't missing
                    do {
                        newDate = newDate.subtract(1, 'days')
                    } while (missing.indexOf(newDate.format('YYYY-MM-DD')) !== -1)
                    if (newDate < start) {
                        newDate = start
                    }
                } else {
                    // Find the next date that isn't missing
                    do {
                        newDate = newDate.add(1, 'days')
                    } while (missing.indexOf(newDate.format('YYYY-MM-DD')) !== -1)
                    if (newDate > end) {
                        newDate = end
                    }
                }
            }
            date = newDate.format('YYYY-MM-DD')
        }

        dispatch({ type: types.VIEWER_COMPARE_SELECT_DATE, date: date })
        dispatch(loadImageList(state.compare.withSelf, date, time))
    }
}

export function clearSelectedDateAndTime() {
    return {
        type: types.VIEWER_COMPARE_CLEAR_SELECTED_DATE_AND_TIME
    }
}

export function selectTime(time) {
    return (dispatch, getState) => {
        const state = getState()
        // Final 3D rendered image has no date
        if (state.compare.with3d) {
            return
        }
        const imageList = state.associatedCamera.imageList
        if (imageList.images.length > 0) {
            const projectIndex = state.navigation.currentProject
            let cameraIndex
            if (state.compare.withSelf) {
                cameraIndex = state.navigation.currentCamera
            } else {
                cameraIndex = state.camera.associatedCamera
            }
            let image = getSelectedImageFromList(imageList.images, time)
            if (!image) {
                // No matching image was found, use the latest for that day
                image = imageList.images[imageList.images.length - 1]
            }
            time = image.time
            dispatch(selectImage(projectIndex, cameraIndex, image))
        }
        dispatch({
            type: types.VIEWER_COMPARE_SELECT_TIME,
            time: time
        })
    }
}

export function showNoBIMMessage() {
    return {
        type: types.VIEWER_COMPARE_SHOW_NO_BIM_MESSAGE
    }
}

export function hideNoBIMMessage() {
    return {
        type: types.VIEWER_COMPARE_HIDE_NO_BIM_MESSAGE
    }
}

export function cameraModelAssociation(project, cameraIndex) {
    return (dispatch, getState) => {
        let cameraModelAssociations = []
        let modelCameraAssociations = {}
        if (!project) {
            return
        }
        if (!('cameras' in project)) {
            return
        }

        const cameras = project.cameras

        for (const i in cameras) {
            if ('associatedModels' in cameras[i]) {
                cameraModelAssociations[i] = [];
                for (const index in cameras[i].associatedModels) {
                    // For each item in the associated models array
                    // Add the model details to an object in cameraModelAssociations
                    cameraModelAssociations[i].push({
                        'associatedCamera' : parseInt(i, 10),
                        'index' : cameras[i].associatedModels[index],
                        'type': cameras[cameras[i].associatedModels[index]].bim_model_type
                    })
                    
                    // Populate the associated cameras
                    if (cameras[cameras[i].associatedModels[index]]) {
                        modelCameraAssociations[cameras[i].associatedModels[index]] = parseInt(i, 10)

                    }
                }
            }
        }

        dispatch({
            type: types.VIEWER_ASSOCIATE_MODELS_AND_CAMERAS,
            cameraModelAssociations: cameraModelAssociations,
            modelCameraAssociations: modelCameraAssociations
        })

        let associatedCamera = null
        let cameraIsModel = false

        if (cameraIndex in modelCameraAssociations) {
            cameraIsModel = true
            associatedCamera = modelCameraAssociations[cameraIndex]
        } else if (cameraIndex in cameraModelAssociations) {
            associatedCamera = cameraModelAssociations[cameraIndex]
        }
        dispatch(setCameraIsModel(cameraIsModel))
        dispatch(setAssociatedCamera(associatedCamera))
    }
}

function getSelectedImageFromList (images, time)  {
    for (var i=0; i < images.length; i++) {
        const image = images[i]
        if (time === image.time) {
            return image
        }
    }
}

export function switchComparisonImages(date, time) {
    return (dispatch, getState) => {
        const state = getState()

        if (!state.compare.enabled) {
            return false
        }
        if (date) {
            dispatch({ type: types.VIEWER_COMPARE_SELECT_DATE, date: date })
            dispatch(loadImageList(state.compare.withSelf, date, time))
        }
    }
}