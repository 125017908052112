import * as types from './actionTypes'
import { API_BASE_URL } from '../config'
import { fetchWithToken } from '../util/accessToken'

export function loadWeather(date) {
    return (dispatch, getState) => {
        dispatch({ type: types.VIEWER_LOAD_WEATHER_REQUEST})
        const state = getState()
        const proj = state.navigation.currentProject
        const cam = state.navigation.currentCamera
        if (proj === null || cam === null) {
            dispatch(
                {
                    type: types.VIEWER_LOAD_WEATHER_ERROR,
                    errors: {}
                }
            )
        } else {

            return fetchWithToken(
                `${API_BASE_URL}/api/viewer/projects/${proj}/cameras/${cam}/weather/${date}`,
                {
                    method: 'GET',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                },
                dispatch,
                getState
            ).then(response => {
               if (!response.ok) {
                   response.json().then(data => {
                       if (data.errors) {
                           dispatch(
                               {
                                   type: types.VIEWER_LOAD_WEATHER_ERROR,
                                   errors: data.errors
                               }
                           )
                       } else {
                           dispatch(
                               {
                                   type: types.VIEWER_LOAD_WEATHER_ERROR,
                                   errors: {
                                       unknownErrorType: {
                                           unknownError: "There was an unexpected error."
                                       }
                                   }
                               }
                           )
                       }
                   })
               } else {
                   if (response.status === 204) {
                       dispatch(
                           {
                               type: types.VIEWER_LOAD_WEATHER_SUCCESS,
                               weather: []
                           }
                       )
                   } else {
                       response.json().then(data => {
                           if (data.errors) {
                               dispatch(
                                    {
                                        type: types.VIEWER_LOAD_WEATHER_ERROR,
                                        errors: data.errors
                                    }
                                )
                            } else {
                                dispatch(
                                    {
                                        type: types.VIEWER_LOAD_WEATHER_SUCCESS,
                                        weather: data
                                    }
                                )
                            }
                        })
                    }
                }
            })
            .catch((response) => {
                dispatch(
                    {
                        type: types.VIEWER_LOAD_WEATHER_ERROR,
                        errors: {
                           unknownErrorType: {
                                unknownError: "There was an unexpected error"
                            }
                        }
                    }
                )
            })
        }
    }
}
