import initialState from './initialState'
import {
    VIEWER_SELECT_IMAGE,
    VIEWER_CLEAR_CURRENT_IMAGE,
    LOGIN_RESPONSE
} from '../actions/actionTypes'

export default function currentImage(state = initialState.currentImage, action) {
    switch (action.type) {
        case VIEWER_SELECT_IMAGE:
            return Object.assign(
                {},
                state,
                {
                    width: action.width,
                    height: action.height,
                    time: action.time,
                    date: action.date,
                    filename: action.image.image,
                    imageURL: action.image_url,
                    downloadURL: action.download_url,
                    thumbnailURL: action.thumbnail_url
                }
            )
        case LOGIN_RESPONSE:
            return initialState.currentImage
        case VIEWER_CLEAR_CURRENT_IMAGE:
            return initialState.currentImage
        default:
            return state
    }
}
