const dev = {
    API_BASE_URL: "http://dev.service.lobstervision3.tv",
    PROD_API_BASE_URL: "https://service.lobstervision.tv",
    UAT_BASE_URL: "https://service-uat.lobstervision.tv",
    DEV_API_BASE_URL: "http://dev.service.lobstervision3.tv",
    ADMIN_URL: "http://localhost:3000"
}

const uat = {
    API_BASE_URL: "https://service-uat.lobstervision.tv",
    ADMIN_URL: "https://admin-uat.lobstervision.tv"
}

const prod = {
    API_BASE_URL: "https://service.lobstervision.tv",
    ADMIN_URL: "https://admin.lobstervision.tv"
}

let config
if (process.env.NODE_ENV === "production") {
    config = process.env.REACT_APP_STAGE === 'uat' ? uat : prod
} else {
    config = dev
}

export const API_BASE_URL = config["API_BASE_URL"]
export const ADMIN_URL = config["ADMIN_URL"]
export const RECAPTCHA_SITE_KEY = '6Lelil8UAAAAAEH1Pr8mSy4gnw1OG2YjoN24HB-q'
