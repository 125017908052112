import initialState from './initialState'
import {
    VIEWER_PHOTO_SHOW_DATETIME,
    VIEWER_PHOTO_HIDE_DATETIME,
    VIEWER_TOGGLE_FULLSCREEN,
    VIEWER_ENABLE_AUTO_REFRESH,
    VIEWER_DISABLE_AUTO_REFRESH,
    LOGIN_RESPONSE
} from '../actions/actionTypes'

export default function photoViewer(state = initialState.photoViewer, action) {
    switch (action.type) {
        case VIEWER_PHOTO_SHOW_DATETIME:
            return Object.assign(
                {},
                state,
                {
                    dateTimeVisible: true
                }
            )
        case VIEWER_PHOTO_HIDE_DATETIME:
            return Object.assign(
                {},
                state,
                {
                    dateTimeVisible: false
                }
            )
        case VIEWER_TOGGLE_FULLSCREEN:
            const fullscreen = state.fullscreen
            return Object.assign(
                {},
                state,
                {
                    fullscreen: !fullscreen
                }
            )
        case VIEWER_ENABLE_AUTO_REFRESH:
            return Object.assign(
                {},
                state,
                {
                    autoRefreshEnabled: true
                }
            )
        case VIEWER_DISABLE_AUTO_REFRESH:
            return Object.assign(
                {},
                state,
                {
                    autoRefreshEnabled: false
                }
            )
        case LOGIN_RESPONSE:
            return initialState.photoViewer
        default:
            return state
    }
}
