import * as types from './actionTypes'
import { applyConstraints } from '../util/image'
import { showPanWindow, hidePanWindow } from './panWindowActions'

export function updateWindowDimensions(offsetX, offsetY, displayWidth, displayHeight) {
    return (dispatch, getState) => {
        // Check to see if anything has changed
        const state = getState()
        if (
            state.photoDimensions.offsetX === offsetX &&
            state.photoDimensions.offsetY === offsetY &&
            state.photoDimensions.displayWidth === displayWidth &&
            state.photoDimensions.displayHeight === displayHeight
        ) {
            return
        }

        dispatch ({
            type: types.VIEWER_PHOTO_UPDATE_IMAGE_DIMENSIONS,
            dimensions: {
                offsetX: offsetX,
                offsetY: offsetY,
                displayWidth: displayWidth,
                displayHeight: displayHeight,
                smoothTransition: false
            }
        })
        dispatch(updateImageDimensions())
    }
}

export function updateImageDimensions(reset = false) {
    return (dispatch, getState) => {
        const state = getState()
        const imageWidth = state.currentImage.width
        const imageHeight = state.currentImage.height

        let dimensions = Object.assign(
            {},
            {
                offsetX: state.photoDimensions.offsetX,
                offsetY: state.photoDimensions.offsetY,
                displayWidth: state.photoDimensions.displayWidth,
                displayHeight: state.photoDimensions.displayHeight,
                imageWidth: state.currentImage.width,
                imageHeight: state.currentImage.height,
                zoomed: state.photoDimensions.zoomed,
                zoomedAllTheWayIn: state.photoDimensions.zoomedAllTheWayIn,
                smoothTransition: false
            }
        )

        // Calculate optmial scale
        dimensions['scale'] = dimensions['displayWidth'] / dimensions['displayHeight']
            > imageWidth / imageHeight
            ? dimensions['displayHeight'] / imageHeight
            : dimensions['displayWidth'] / imageWidth

        // Recalculate scale constraints
        dimensions['minScale'] = dimensions['scale']
        dimensions['maxScale'] = 4.0

        const permittedToZoom = "settings" in state.user ?
            state.user.settings.permitted_to_zoom : false

        if (reset === true || !state.photoDimensions.zoomed) {
            // Centre the image if it hasn't been manually zoomed
            dimensions['panX'] = (dimensions['displayWidth'] - imageWidth * dimensions['scale']) / 2 / dimensions['scale']
            dimensions['panY'] = (dimensions['displayHeight'] - imageHeight * dimensions['scale']) / 2 / dimensions['scale']
            dimensions['zoomed'] = false
            dimensions['zoomedAllTheWayIn'] = false
        } else if (state.photoDimensions.displayWidth
            && state.photoDimensions.displayHeight) {
            const parameters = applyConstraints(
                state.photoDimensions.panX
                    - (state.photoDimensions.displayWidth - dimensions['displayWidth'])
                    / 2 / state.photoDimensions.scale,
                state.photoDimensions.panY
                    - (state.photoDimensions.displayHeight - dimensions['displayHeight'])
                    / 2 / state.photoDimensions.scale,
                state.photoDimensions.scale,
                0,
                0,
                state.photoDimensions.displayWidth,
                state.photoDimensions.displayHeight,
                state.currentImage.width,
                state.currentImage.height,
                permittedToZoom
            )
            dimensions['panX'] = parameters['panX']
            dimensions['panY'] = parameters['panY']
            dimensions['scale'] = parameters['scale']
        }
        dispatch({
            type: types.VIEWER_PHOTO_UPDATE_IMAGE_DIMENSIONS,
            dimensions: dimensions
        })
    }
}

export function zoomIn(event) {
    return (dispatch, getState) => {
        event.preventDefault()
        const state = getState()
        const photoDimensions = state.photoDimensions
        const currentImage = state.currentImage
        if (!currentImage.width || !currentImage.height) {
            return
        }
        const minScale = photoDimensions.minScale
        const maxScale = photoDimensions.maxScale
        const levels = zoomLevels(minScale, maxScale)
        let scale = photoDimensions.scale
        if (scale < minScale) { scale = minScale }
        for (var i = 0; i < levels.length; i++) {
            if (levels[i] > scale * 1.05) {
                const deltaScale = levels[i] - scale
                dispatch(
                    panAndZoom(0, 0,
                        deltaScale,
                        photoDimensions.displayWidth / 2,
                        photoDimensions.displayHeight / 2,
                        true
                    )
                )
                break
            }
        }
        dispatch(showPanWindow(true))
    }
}

export function zoomOut(event) {
    return (dispatch, getState) => {
        event.preventDefault()
        const state = getState()
        const photoDimensions = state.photoDimensions
        const minScale = photoDimensions.minScale
        const maxScale = photoDimensions.maxScale
        const levels = zoomLevels(minScale, maxScale).reverse()

        for (var i = 0; i < levels.length; i++) {
          if (levels[i] < photoDimensions.scale / 1.05) {
              const deltaScale = levels[i] - photoDimensions.scale
              dispatch(
                 panAndZoom(0, 0,
                     deltaScale,
                     photoDimensions.displayWidth / 2,
                     photoDimensions.displayHeight / 2,
                     true)
              )
            break
          }
        }
        if (i < levels.length - 1) {
            dispatch(showPanWindow(true))
        } else {
            dispatch(hidePanWindow())
        }
    }
}

export function zoomRightOut() {
    return (dispatch, getState) => {
        const state = getState()
        const photoDimensions = state.photoDimensions
        const minScale = photoDimensions.minScale
        const deltaScale = minScale - photoDimensions.scale
        dispatch(
            panAndZoom(0, 0,
                deltaScale,
                photoDimensions.displayWidth / 2,
                photoDimensions.displayHeight / 2,
                true)
        )
        dispatch(hidePanWindow())
    }
}

export function panAndZoom(deltaX, deltaY, deltaScale, centreX, centreY, smooth) {
    return (dispatch, getState) => {
        const state = getState()
        const oldScale = state.photoDimensions.scale
        const scale = oldScale + deltaScale
        const ratio = (1 - oldScale / scale ) / oldScale
        const permittedToZoom = checkZoomPermission(state.user)
        const newParameters = applyConstraints(
            state.photoDimensions.panX - deltaX / oldScale,
            state.photoDimensions.panY - deltaY / oldScale,
            scale,
            centreX * ratio,
            centreY * ratio,
            state.photoDimensions.displayWidth,
            state.photoDimensions.displayHeight,
            state.currentImage.width,
            state.currentImage.height,
            permittedToZoom
        )
        newParameters['smoothTransition'] = smooth
        dispatch({
            type: types.VIEWER_PHOTO_SET_PAN_AND_ZOOM,
            parameters: newParameters
        })
    }
}

export function pan(deltaX, deltaY, smooth) {
    return (dispatch, getState) => {
        const state = getState()
        const scale = state.photoDimensions.scale
        const permittedToZoom = checkZoomPermission(state.user)
        const newParameters = applyConstraints(
            state.photoDimensions.panX - deltaX / scale,
            state.photoDimensions.panY - deltaY / scale,
            scale,
            0,
            0,
            state.photoDimensions.displayWidth,
            state.photoDimensions.displayHeight,
            state.currentImage.width,
            state.currentImage.height,
            permittedToZoom
        )
        newParameters['smoothTransition'] = smooth
        dispatch({
            type: types.VIEWER_PHOTO_SET_PAN_AND_ZOOM,
            parameters: newParameters
        })
    }
}

export function zoomLevels(min, max) {
    let levels = []
    if (min > 0.2) {
        levels.push(min)

        levels.push(0.25)
        levels.push(0.5)
        levels.push(0.75)
        levels.push(1)
        levels.push(1.5)
        levels.push(2)
        levels.push(2.5)
        levels.push(3)
        levels.push(max)
    } else {
        levels.push(min)
        levels.push(0.2)
        levels.push(0.25)
        levels.push(0.35)
        levels.push(0.5)
        levels.push(0.75)
        levels.push(1)
        levels.push(1.5)
        levels.push(2)
        levels.push(2.5)
        levels.push(3)
        levels.push(max)
    }
    return levels
}

export function checkZoomPermission(user) {
    if (!("settings" in user)) {
        return false
    }
    return user.settings.permitted_to_zoom
}
