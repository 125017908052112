import initialState from './initialState'
import {
    LOG_REQUEST
} from '../actions/actionTypes'

// import * as uniqid from 'uniqid' // fails on test, but works in browser
var uniqid = require('uniqid') // appears to work on both...

export default function log(state = initialState.log, action) {

    switch (action.type) {
        case LOG_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    logs: [
                        ...state.logs,
                        {
                            level: action.level,
                            message: action.message,
                            id: uniqid()
                        }

                    ]
                }
            )

        default:
            return state
    }
}
