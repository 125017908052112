import initialState from './initialState'
import {
    VIEWER_LOAD_CAMERA_DATES_REQUEST,
    VIEWER_LOAD_CAMERA_DATES_RESPONSE,
    VIEWER_LOAD_CAMERA_DATES_ERROR,
    VIEWER_LOAD_IMAGE_LIST_REQUEST,
    VIEWER_LOAD_IMAGE_LIST_RESPONSE,
    VIEWER_LOAD_IMAGE_LIST_ERROR,
    VIEWER_SELECT_CAMERA_DATE,
    VIEWER_SELECT_CAMERA_TIME,
    VIEWER_CLEAR_IMAGE_LIST,
    VIEWER_SET_ASSOCIATED_CAMERA,
    VIEWER_SET_IS_MODEL_STATE,
    LOGIN_RESPONSE
} from '../actions/actionTypes'

export default function camera(state = initialState.camera, action) {

    switch (action.type) {
        case VIEWER_LOAD_CAMERA_DATES_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    dates: {
                        loading: true,
                        success: false,
                        errors: {},
                    }
                }
            )
        case VIEWER_LOAD_CAMERA_DATES_RESPONSE:
            return Object.assign(
                {},
                state,
                {
                    dates: {
                        loading: false,
                        success: true,
                        errors: {},
                    }
                }
            )
        case VIEWER_LOAD_CAMERA_DATES_ERROR:
            return Object.assign(
                {},
                state,
                {
                    dates: {
                        loading: false,
                        success: false,
                        errors: action.errors,
                    },
                    imageList: {
                        loading: state.imageList.loading,
                        success: state.imageList.success,
                        errors: state.imageList.errors,
                        images: []
                    }
                }
            )
        case VIEWER_LOAD_IMAGE_LIST_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    imageList: {
                        loading: true,
                        success: false,
                        errors: {},
                        images: state.imageList.images
                    }
                }
            )
        case VIEWER_LOAD_IMAGE_LIST_RESPONSE:
            return Object.assign(
                {},
                state,
                {
                    imageList: {
                        loading: false,
                        success: true,
                        errors: {},
                        images: action.images
                    }
                }
            )
        case VIEWER_LOAD_IMAGE_LIST_ERROR:
            return Object.assign(
                {},
                state,
                {
                    imageList: {
                        loading: false,
                        success: false,
                        errors: action.errors,
                        images: []
                    }
                }
            )
        case VIEWER_SELECT_CAMERA_DATE:
            return Object.assign(
                {},
                state,
                {
                    selectedDate: action.date,
                    imageList: {
                        loading: state.imageList.loading,
                        success: state.imageList.success,
                        errors: state.imageList.errors,
                        images: state.imageList.images
                    }
                }
            )
        case VIEWER_SELECT_CAMERA_TIME:
            return Object.assign(
                {},
                state,
                {
                    selectedTime: action.time
                }
            )
        case VIEWER_CLEAR_IMAGE_LIST:
            return Object.assign(
                {},
                state,
                {
                    imageList: initialState.camera.imageList
                }
            )
        case VIEWER_SET_ASSOCIATED_CAMERA:
            return Object.assign(
                {},
                state,
                {
                    associatedCamera: action.camera
                }
            )
        case VIEWER_SET_IS_MODEL_STATE:
            return Object.assign(
                {},
                state,
                {
                    isModel: action.isModel
                }

            )
        case LOGIN_RESPONSE:
            return initialState.camera
        default:
            return state
    }
}
