import * as types from './actionTypes'
import { API_BASE_URL } from '../config'
import { fetchWithToken } from '../util/accessToken'
import { selectTime } from './compareActions'
import { notAuthorisedResponse } from './authActions'

export function loadImageList(withSelf = null, date = null, time = null) {
    return (dispatch, getState) => {
        dispatch({ type: types.VIEWER_ASSOCIATED_CAMERA_LOAD_IMAGE_LIST_REQUEST })
        const state = getState()
        if (withSelf === null) {
            withSelf = state.compare.withSelf
        }
        if (date === null) {
            date = state.compare.selectedDate
        }

        const proj = state.navigation.currentProject
        //const currentProj = state.user.projects[proj]
        let camera

        if (state.camera.isModel) {
            if (state.compare.withSelf === 'isModel') {
                camera = state.navigation.currentCamera
            } else {
                camera = state.camera.associatedCamera
            }
        } else {
            if (withSelf === 'isContract') {
                const contractCamera = state.camera.associatedCamera.find(({type}) => type === 'contract');
                camera = contractCamera.index
            } else if (withSelf === 'isDelivery') {
                const deliveryCamera = state.camera.associatedCamera.find(({type}) => type === 'delivery');
                camera = deliveryCamera.index
            } else if (withSelf === 'isModel') {
                if (Array.isArray(state.camera.associatedCamera)) {
                    const [firstCamera] = state.camera.associatedCamera
                    camera = firstCamera.index
                } else {
                    camera = state.camera.associatedCamera
                }
            } else {
                camera = state.navigation.currentCamera
            }
        }
        
        return fetchWithToken(
            `${API_BASE_URL}/api/viewer/projects/${proj}/cameras/${camera}/images/${date}`,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        ).then(response => {
            if (response.status === 401) {
                dispatch(notAuthorisedResponse())
            }
            else if (!response.ok) {
                response.json().then(data => {
                    if (data.errors) {
                        dispatch(
                            {
                                type: types.VIEWER_ASSOCIATED_CAMERA_LOAD_IMAGE_LIST_ERROR,
                                errors: data.errors
                            }
                        )
                    } else {
                        dispatch(
                            {
                                type: types.VIEWER_ASSOCIATED_CAMERA_LOAD_IMAGE_LIST_ERROR,
                                errors: {
                                    unknownErrorType: {
                                        unknownError: "There was an unexpected error"
                                    }
                                }
                            }
                        )
                    }
                })
            } else {
                if (response.status === 204) {
                    dispatch(
                        {
                            type: types.VIEWER_ASSOCIATED_CAMERA_LOAD_IMAGE_LIST_ERROR,
                            errors: {
                                noDatesErrorType: {
                                    noDatesError: "There are no images to display for this date."
                                }
                            }
                        }
                    )
                    if (!state.compare.withSelf) {
                        dispatch(
                            {
                                type: types.VIEWER_COMPARE_SHOW_NO_BIM_MESSAGE
                            }
                        )
                    }
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.VIEWER_ASSOCIATED_CAMERA_LOAD_IMAGE_LIST_ERROR,
                                    errors: data.errors
                                }
                            )
                        } else {
                            dispatch(
                                {
                                    type: types.VIEWER_ASSOCIATED_CAMERA_LOAD_IMAGE_LIST_RESPONSE,
                                    images: data
                                }
                            )
                            if (data.length < 1) {
                                // TODO:: Signal some kind of error
                                return
                            }
                            let image
                            // If no time specified, use the image previous to
                            // the one currently selected
                            if (time === null) {
                                let pos
                                for (var i=0; i < data.length; i++) {
                                    if (data[i].time === state.camera.selectedTime) {
                                        pos = i
                                        break
                                    }
                                }
                                // Just in case
                                if (pos === undefined) {
                                    pos = 0
                                }
                                // Only go back one image if a previous image
                                // exists
                                if (pos > 0) {
                                    pos = pos - 1
                                }
                                image = data[pos]
                                time = image.time
                            }
                            dispatch(selectTime(time))
                        }
                    })
                }
            }
        })
        .catch((response) => {
            dispatch(
                {
                    type: types.VIEWER_ASSOCIATED_CAMERA_LOAD_IMAGE_LIST_ERROR,
                    errors: {
                        unknownErrorType: {
                            unknownError: "There was an unexpected error"
                        }
                    }
                }
            )
        })
    }
}

export function clearImageList() {
    return {
        type: types.VIEWER_ASSOCIATED_CAMERA_CLEAR_IMAGE_LIST
    }
}

export function reset() {
    return {
        type: types.VIEWER_ASSOCIATED_CAMERA_RESET
    }
}
