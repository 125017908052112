import initialState from './initialState'
import {
    VIEWER_SELECT_TAB_PROJECTS,
    VIEWER_SELECT_TAB_CAMERAS,
    VIEWER_SELECT_TAB_MAP,
    VIEWER_SELECT_TAB_PHOTOS,
    VIEWER_SELECT_TAB_VIDEO,
    VIEWER_SELECT_TAB_OPENSPACE,
    VIEWER_SELECT_PROJECT,
    VIEWER_SELECT_CAMERA,
    VIEWER_SELECT_PROJECT_AND_CAMERA,
    VIEWER_CLEAR_SELECTED_TAB,
    VIEWER_SELECT_TAB_SETTINGS,
    CARDS_SET_CARD_MODE_ACTIVE,
    CARDS_HIDE_MENU,
    CARDS_SHOW_MENU,
    CARDS_SET_SECONDARY_MENU,
    CARDS_CLEAR_SECONDARY_MENU,
    LOGIN_RESPONSE
} from '../actions/actionTypes'

import {CAMERAS, PHOTOS, VIDEO, SETTINGS, MAP, OPENSPACE, PROJECTS} from '../actions/navigationActions'

export default function navigation(state = initialState.navigation, action) {

    switch (action.type) {
        case VIEWER_SELECT_TAB_PROJECTS:
            return Object.assign(
                {},
                state,
                {
                    currentTab: PROJECTS
                }
            )
        case VIEWER_SELECT_TAB_CAMERAS:
            return Object.assign(
                {},
                state,
                {
                    currentTab: CAMERAS
                }
            )
        case VIEWER_SELECT_TAB_MAP:
            return Object.assign(
                {},
                state,
                {
                    currentTab: MAP
                }
            )
        case VIEWER_SELECT_TAB_PHOTOS:
            return Object.assign(
                {},
                state,
                {
                    currentTab: PHOTOS,
                    //returnToTab: PHOTOS
                }
            )
        case VIEWER_SELECT_TAB_VIDEO:
            return Object.assign(
                {},
                state,
                {
                    currentTab: VIDEO,
                    //returnToTab: VIDEO
                }
            )
        case VIEWER_SELECT_TAB_OPENSPACE:
            return Object.assign(
                {},
                state,
                {
                    currentTab: OPENSPACE
                }
            )
        case VIEWER_SELECT_PROJECT:
            return Object.assign(
                {},
                state,
                {
                    currentProject: action.projectIndex
                }
            )
        case VIEWER_SELECT_CAMERA:
            return Object.assign(
                {},
                state,
                {
                    currentCamera: action.cameraIndex
                }
            )

        case VIEWER_SELECT_PROJECT_AND_CAMERA:
            return Object.assign(
                {},
                state,
                {
                    currentProject: action.projectIndex,
                    currentCamera: action.cameraIndex
                }
            )
        case VIEWER_CLEAR_SELECTED_TAB:
            return Object.assign(
                {},
                state,
                {
                    currentTab: null
                }
            )
        case VIEWER_SELECT_TAB_SETTINGS:
            return Object.assign(
                {},
                state,
                {
                    currentTab: SETTINGS,
                    returnToTab: SETTINGS
                }
            )
        case CARDS_SET_CARD_MODE_ACTIVE:
            return Object.assign(
                {},
                state,
                {
                    cardMode: true
                }
            )
        case CARDS_SHOW_MENU:
          return Object.assign(
              {},
              state,
              {
                menuVisible: true,
              }
          );
        case CARDS_HIDE_MENU:
          return Object.assign(
              {},
              state,
              {
                menuVisible: false,
              }
          );
        case CARDS_SET_SECONDARY_MENU:
          return Object.assign(
              {},
              state,
              {
                secondaryMenu: action.secondaryMenu,
              }
          );
        case CARDS_CLEAR_SECONDARY_MENU:
          return Object.assign(
              {},
              state,
              {
                secondaryMenu: null,
              }
          );
        case LOGIN_RESPONSE:
            return initialState.navigation
        default:
            return state
    }
}
