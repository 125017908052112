import React, { Component } from 'react';
import '../loader.css';

class Loading extends Component {
    render() {
        return (
            <div className="loader">
                <img alt='' src={require('../assets/lobster-pictures-logo-retina.png')} />
                <h1 className="loading-text">Loading</h1>
                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
        )
    }
}

export default Loading;
