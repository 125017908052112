import * as types from './actionTypes'
import { API_BASE_URL } from '../config'
import { updateImageDimensions } from './photoDimensionsActions'
import { fetchWithToken } from '../util/accessToken'

export function initialImageLoad() {
    return (dispatch, getState) => {
        const state = getState()

        let dimensions = {}

        if (!state.photoDimensions.initialOffsetX && state.photoDimensions.displayWidth) {
            let xCalc =  (state.photoDimensions.displayWidth - state.photoDimensions.imageWidth * state.photoDimensions.scale) / 2 / state.photoDimensions.scale
            dimensions['initialOffsetX'] = xCalc * state.photoDimensions.scale;
        }

        if (!state.photoDimensions.initialOffsetY && state.photoDimensions.imageWidth) {
            let yCalc = (state.photoDimensions.displayHeight - state.currentImage.height * state.photoDimensions.scale) / 2 / state.photoDimensions.scale
            dimensions['initialOffsetY'] = yCalc * state.photoDimensions.scale;
        }

        dispatch({
            type: types.VIEWER_IMAGE_LOAD,
            dimensions: dimensions
        })
    }
}

export function selectImage(project, camera, image) {
    return (dispatch, getState) => {
        if (!image) {
            return
        }

        const state = getState()
        const prevImageWidth = state.currentImage.width
        const prevImageHeight = state.currentImage.height
        let imageResolutionChanged = false

        if ('exif' in image) {
            if ((image.exif.Width !== prevImageWidth) ||
                (image.exif.Height !== prevImageHeight))
            {
                imageResolutionChanged = true
            }
            dispatch(
                {
                    type: types.VIEWER_SELECT_IMAGE,
                    image: image,
                    project: project,
                    camera: camera,
                    time: image.time,
                    date: image.date_unix,
                    width: image.exif.Width,
                    height: image.exif.Height,
                    thumbnail_url: image.thumbnail_url,
                    download_url: image.download_url,
                    image_url: image.image_url

                }
            )
        } else {
            dispatch(
                {
                    type: types.VIEWER_SELECT_IMAGE,
                    image: image,
                    project: project,
                    camera: camera,
                    time: image.time,
                    width: undefined,
                    height: undefined,
                    thumbnail_url: image.thumbnail_url,
                    image_url: image.image_url
                }
            )
        }

        if (imageResolutionChanged) {
            dispatch(updateImageDimensions(true))
        }
        dispatch(initialImageLoad())
    }
}

export function downloadImage(project, camera, image) {
    return (dispatch, getState) => {
        dispatch({ type: types.VIEWER_PHOTO_DOWNLOAD })
        return fetchWithToken(
             `${API_BASE_URL}/api/viewer/projects/${project}/cameras/${camera}/image/${image}/download`,
             {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
    }
}


export function clear() {
    return {
        type: types.VIEWER_CLEAR_CURRENT_IMAGE
    }
}
