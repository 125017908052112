import * as types from './actionTypes'

export function selectImage(project, camera, image) {
    return (dispatch, getState) => {
        if (!image) {
            return
        }

        if ('exif' in image) {
            dispatch(
                {
                    type: types.VIEWER_SELECT_COMPARE_IMAGE,
                    image: image,
                    project: project,
                    camera: camera,
                    time: image.time,
                    date: image.date_unix,
                    width: image.exif.Width,
                    height: image.exif.Height,
                    thumbnail_url: image.thumbnail_url,
                    image_url: image.image_url

                }
            )
        } else {
            dispatch(
                {
                    type: types.VIEWER_SELECT_COMPARE_IMAGE,
                    image: image,
                    project: project,
                    camera: camera,
                    time: image.time,
                    date: image.date_unix,
                    width: undefined,
                    height: undefined,
                    thumbnail_url: image.thumbnail_url,
                    image_url: image.image_url
                }
            )
        }
    }
}

export function clear() {
    return {
        type: types.VIEWER_CLEAR_COMPARE_IMAGE
    }
}
