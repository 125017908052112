import initialState from './initialState'
import {
    VIEWER_COMPARE_ENABLE,
    VIEWER_COMPARE_DISABLE,
    VIEWER_COMPARE_CHANGE_TARGET,
    VIEWER_COMPARE_CHANGE_MODE,
    VIEWER_COMPARE_CHANGE_WIPE_ORIENTATION,
    //VIEWER_COMPARE_CHANGE_WIPE_POSITION,
    VIEWER_COMPARE_CHANGE_OPACITY,
    VIEWER_COMPARE_SELECT_DATE,
    VIEWER_COMPARE_SELECT_TIME,
    VIEWER_COMPARE_CLEAR_SELECTED_DATE_AND_TIME,
    VIEWER_COMPARE_SHOW_NO_BIM_MESSAGE,
    VIEWER_COMPARE_HIDE_NO_BIM_MESSAGE,
    VIEWER_COMPARE_SELECT_TIMELINE,
    VIEWER_SELECT_PROJECT_AND_CAMERA,
    VIEWER_ASSOCIATE_MODELS_AND_CAMERAS,
    VIEWER_COMPARE_CALENDAR_HIDE,
    VIEWER_COMPARE_CALENDAR_SHOW,
    LOGIN_RESPONSE,
    VIEWER_COMPARE_SET_DRAGGING
} from '../actions/actionTypes'

import {
    COMPARE_WIPE
} from '../actions/compareActions'

export default function compare(state = initialState.compare, action) {

    switch (action.type) {
        case VIEWER_COMPARE_ENABLE:
            return Object.assign(
                {},
                state,
                {
                    enabled: true
                }
            )
        case VIEWER_COMPARE_DISABLE:
            return Object.assign(
                {},
                state,
                {
                    enabled: false
                }
            )
        case VIEWER_COMPARE_CHANGE_TARGET:
            return Object.assign(
                {},
                state,
                {
                    withSelf: action.withSelf,
                    with3d: action.with3d,
                }
            )
        case VIEWER_COMPARE_CHANGE_MODE:
            return Object.assign(
                {},
                state,
                {
                    mode: action.mode,
                    wipePosition: 0.5,
                    opacity: 0.5
                }
            )
        case VIEWER_COMPARE_CHANGE_WIPE_ORIENTATION:
            return Object.assign(
                {},
                state,
                {
                    horizontalWipe: action.horizontalWipe,
                    wipePosition: 0.5
                }
            )
        case VIEWER_COMPARE_CHANGE_OPACITY:
            return Object.assign(
                {},
                state,
                {
                    opacity: action.opacity
                }
            )
        case VIEWER_COMPARE_SELECT_DATE:
            return Object.assign(
                {},
                state,
                {
                    selectedDate: action.date
                }
            )
        case VIEWER_COMPARE_SELECT_TIME:
            return Object.assign(
                {},
                state,
                {
                    selectedTime: action.time
                }
            )
        case VIEWER_COMPARE_CLEAR_SELECTED_DATE_AND_TIME:
            return Object.assign(
                {},
                state,
                {
                    selectedDate: null,
                    selectedTime: null
                }
            )
        case VIEWER_COMPARE_SHOW_NO_BIM_MESSAGE:
            return Object.assign(
                {},
                state,
                {
                    showNoBIMMessage: true
                }
            )
        case VIEWER_COMPARE_HIDE_NO_BIM_MESSAGE:
            return Object.assign(
                {},
                state,
                {
                    showNoBIMMessage: false
                }
            )
        case VIEWER_COMPARE_SELECT_TIMELINE:
            return Object.assign(
                {},
                state,
                {
                    selectedTimeline: action.timeline
                }
            )
        case VIEWER_ASSOCIATE_MODELS_AND_CAMERAS:
            return Object.assign(
                {},
                state,
                {
                    cameraModelAssociations: action.cameraModelAssociations,
                    modelCameraAssociations: action.modelCameraAssociations
                }
            )
        case VIEWER_COMPARE_CALENDAR_HIDE:
            return Object.assign(
                {},
                state,
                {
                    showCalendar: false
                }
            )
        case VIEWER_COMPARE_CALENDAR_SHOW:
            return Object.assign(
                {},
                state,
                {
                    showCalendar: true
                }
            )
        case VIEWER_COMPARE_SET_DRAGGING:
            return Object.assign(
                {},
                state,
                {
                    dragging: action.value
                }
            )
        // This is removing the compare enabled state
        // case VIEWER_SELECT_PROJECT_AND_CAMERA:
        case LOGIN_RESPONSE:
            return initialState.compare
        default:
            return state
    }
}
