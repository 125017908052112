import * as types from './actionTypes'

const DATETIME_WINDOW_HIDE_TIMEOUT = 2000

export function showDateTimeWindow() {
    return (dispatch, getState) => {
        dispatch({
            type: types.VIEWER_PHOTO_SHOW_DATETIME
        })
        dispatch(hideDateTimeWindow(true))
    }
}

export function hideDateTimeWindow(delay) {
    return (dispatch, getState) => {
        if (!delay) {
            dispatch({
                type: types.VIEWER_PHOTO_HIDE_DATETIME
            })
        } else {
            if (window.hideDateTimeWindowTimeout) {
                clearTimeout(window.hideDateTimeWindowTimeout)
            }
            window.hideDateTimeWindowTimeout = setTimeout(
                () => {
                    dispatch({
                        type: types.VIEWER_PHOTO_HIDE_DATETIME
                    })
                },
                DATETIME_WINDOW_HIDE_TIMEOUT
            )
        }
    }
}

export function enableAutoRefresh() {
    return {
        type: types.VIEWER_ENABLE_AUTO_REFRESH
    }
}

export function disableAutoRefresh() {
    return {
        type: types.VIEWER_DISABLE_AUTO_REFRESH
    }
}

export function toggleFullscreen() {
    return {
        type: types.VIEWER_TOGGLE_FULLSCREEN
    }
}
