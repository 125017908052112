import initialState from './initialState'
import {
    VIDEO_EDIT_UPDATE,
    VIDEO_EDIT_RESET,
    VIDEO_EDIT_GET_DAYS_AND_TIMES_SUCCESS,
    VIDEO_EDIT_GET_DAYS_AND_TIMES_ERRORS,
    VIDEO_EDIT_GET_DAYS_AND_TIMES_REQUEST,
    VIDEO_EDIT_GET_MATCHING_IMAGE_COUNT_REQUEST,
    VIDEO_EDIT_GET_MATCHING_IMAGE_COUNT_ERRORS,
    VIDEO_EDIT_GET_MATCHING_IMAGE_COUNT_SUCCESS,
    VIDEO_EDIT_SUBMIT_NEW_EDIT_REQUEST,
    VIDEO_EDIT_SUBMIT_NEW_EDIT_RESPONSE,
    VIDEO_EDIT_SUBMIT_NEW_EDIT_ERRORS,
    VIDEO_EDIT_PERMIT_NEXT,
    VIDEO_EDIT_DENY_NEXT
} from '../actions/actionTypes'

export default function videoEdit(state = initialState.videoEdit, action) {
  switch (action.type) {
    case VIDEO_EDIT_UPDATE:
      return Object.assign(
        {},
        state,
        action.data
      )
    case VIDEO_EDIT_GET_DAYS_AND_TIMES_REQUEST:
      return Object.assign(
        {},
        state,
        {
          error: false,
          loading: true,
          errors: {}
        }
      )
    case VIDEO_EDIT_GET_DAYS_AND_TIMES_ERRORS:
      return Object.assign(
        {},
        state,
        {
          error: true,
          loading: false,
          errors: action.errors
        }
      )
    case VIDEO_EDIT_GET_DAYS_AND_TIMES_SUCCESS:
      return Object.assign(
        {},
        state,
        action.data,
        {
          loading: false,
          error: false,
          errors: {}
        }
      )
    case VIDEO_EDIT_GET_MATCHING_IMAGE_COUNT_REQUEST:
      return Object.assign(
        {},
        state,
        {
          loading: true,
          error: false,
          count: undefined,
          minWidth: undefined,
          minHeight: undefined
        }
      )
    case VIDEO_EDIT_GET_MATCHING_IMAGE_COUNT_ERRORS:
      return Object.assign(
        {},
        state,
        {
          loading: false,
          error: true
        }
      )
    case VIDEO_EDIT_GET_MATCHING_IMAGE_COUNT_SUCCESS:
      return Object.assign(
        {},
        state,
        {
          loading: false,
          error: false,
          count: action.matchingImages,
          minWidth: action.minWidth,
          minHeight: action.minHeight
        }
      )
    case VIDEO_EDIT_RESET:
      return {
        loading: false,
        error: false,
        count: undefined,
        projectIndex: action.projectIndex,
        cameraIndex: action.cameraIndex,
        days: [],
        times: {},
        timecode: true,
        minWidth: undefined,
        minHeight: undefined,
        permitNext: true
      }
     case VIDEO_EDIT_SUBMIT_NEW_EDIT_REQUEST:
        return Object.assign(
            {},
            state,
            {
                loading: true,
                error: false,
                errors: {}
            }
        )
    case VIDEO_EDIT_SUBMIT_NEW_EDIT_RESPONSE:
        return Object.assign(
            {},
            state,
            {
                loading: false,
                error: false,
                errors: {},
                email: action.email
            }
        )
    case VIDEO_EDIT_SUBMIT_NEW_EDIT_ERRORS:
        return Object.assign(
            {},
            state,
            {
                loading: false,
                error: true,
                errors: action.error
            }
        )
    case VIDEO_EDIT_PERMIT_NEXT:
        return Object.assign(
            {},
            state,
            {
                permitNext: true
            }
        )
    case VIDEO_EDIT_DENY_NEXT:
        return Object.assign(
            {},
            state,
            {
                permitNext: false
            }
        )
    default:
      return state
  }
}
