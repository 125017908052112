import initialState from './initialState'
import {
    SETTINGS_RESET,
    SETTINGS_CHANGE_PASSWORD_REQUEST,
    SETTINGS_CHANGE_PASSWORD_RESPONSE_ERRORS,
    SETTINGS_CHANGE_PASSWORD_RESPONSE_SUCCESS,
    SETTINGS_VALIDATE_PASSWORD_REQUEST,
    SETTINGS_VALIDATE_PASSWORD_RESPONSE_ERRORS,
    SETTINGS_VALIDATE_PASSWORD_RESPONSE_SUCCESS,
    SETTINGS_CHANGE_MFA_REQUEST,
    SETTINGS_CHANGE_MFA_RESPONSE_ERRORS,
    SETTINGS_CHANGE_MFA_RESPONSE_SUCCESS,
    SETTINGS_GET_QR_CODE_ERRORS,
    SETTINGS_GET_QR_CODE_SUCCESS,
} from '../actions/actionTypes'

export default function settings(state = initialState.settings, action) {

    switch (action.type) {
        case SETTINGS_RESET:
            return Object.assign(
                {},
                state,
                {
                    ...initialState.settings
                }
            )

        case SETTINGS_CHANGE_PASSWORD_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    change_password: {
                        processing: true,
                        success: false,
                        error: false,
                        errors: {},
                        details: {}
                    }
                }
            )

        case SETTINGS_CHANGE_PASSWORD_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    change_password: {
                        processing: false,
                        success: false,
                        error: true,
                        errors: action.errors,
                        details: {}
                    }
                }
            )

        case SETTINGS_CHANGE_PASSWORD_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    change_password: {
                        processing: false,
                        success: true,
                        error: false,
                        errors: {},
                        details: action.details
                    }
                }
            )


        case SETTINGS_VALIDATE_PASSWORD_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    validate_password: {
                        processing: true,
                        success: false,
                        error: false,
                        errors: {},
                        details: {}
                    }
                }
            )

        case SETTINGS_VALIDATE_PASSWORD_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    validate_password: {
                        processing: false,
                        success: false,
                        error: true,
                        errors: action.errors,
                        details: {}
                    }
                }
            )

        case SETTINGS_VALIDATE_PASSWORD_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    validate_password: {
                        processing: false,
                        success: true,
                        error: false,
                        errors: {},
                        details: action.details
                    }
                }
            )

        case SETTINGS_CHANGE_MFA_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    change_mfa: {
                        processing: true,
                        success: false,
                        error: false,
                        errors: {},
                        details: {}
                    }
                }
            )
        case SETTINGS_CHANGE_MFA_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    change_mfa: {
                        processing: false,
                        success: false,
                        error: true,
                        errors: action.errors,
                        details: {}
                    }
                }
            )
        case SETTINGS_CHANGE_MFA_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    change_mfa: {
                        processing: false,
                        success: true,
                        error: false,
                        errors: {},
                        details: action.details,
                        mfa_type: action.details.channel
                    }
                }
            )

        case SETTINGS_GET_QR_CODE_ERRORS:
            return state
        case SETTINGS_GET_QR_CODE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    mfa_qr_code: {
                        processing: false,
                        success: true,
                        error: false,
                        errors: {},
                        url: action.qr_code_url
                    }
                }
            )

        default:
            return state
    }
}
