export function loadAnalytics(id, page, projectsList) {
	/*eslint-disable */
	(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
    (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
    m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    })(window,document,'script','https://www.google-analytics.com/analytics.js','ga')
	/*eslint-enable */
    if (window.ga) {
		window.ga('create', 'UA-264888-6', 'auto')
		if (id) {
			window.ga('set', 'userId', id)
		}
		if (page) {
			trackPageChange(page, projectsList)
		}
	}
}

export function trackPageChange(page, projectsList) {
	if (window.ga) {
		const title = formatPath(page, projectsList)
		window.ga('send', 'pageview', page, {'title': title})
	}
}

export function formatPath(path, projectsList) {
	const parts = path.split('/')
	if (parts.length === 2) {
		return "Camera Select";
	}
	const project = parseInt(parts[3], 10)
	let camera = parseInt(parts[5], 10)
	if (isNaN(project) || isNaN(camera)) {
		return path
	}
	camera = camera + 1 // convert 0-index to human friendly
	if (projectsList.length <= project) {
		return path
	}
	const projectName = projectsList[project].name
	if (parts[6] === 'timelapse') {
		return `${projectName}, Camera ${camera}: Timelapse`
	}
	let params
	if (parts.length === 7) {
		params = 'Latest'
	} else {
		params = parts.slice(7).join(' ')
	}
	return `${projectName}, Camera ${camera}: Photo (${params})`
}
