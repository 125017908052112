export const LOGIN_REQUEST                                      = 'LOGIN_REQUEST'
export const LOGIN_RESPONSE                                     = 'LOGIN_RESPONSE'
export const LOGIN_ERRORS                                       = 'LOGIN_ERRORS'
export const LOGIN_SUCCESS                                      = 'LOGIN_SUCCESS'
export const LOGIN_RESET_ERRORS                                 = 'LOGIN_RESET_ERRORS'
export const SEND_MFA_TOKEN_REQUEST                             = 'SEND_MFA_TOKEN_REQUEST'
export const SEND_MFA_TOKEN_RESPONSE                            = 'SEND_MFA_TOKEN_RESPONSE'
export const SEND_MFA_TOKEN_ERROR                               = 'SEND_MFA_TOKEN_ERROR'
export const VERIFY_MFA_TOKEN_REQUEST                           = 'VERIFY_MFA_TOKEN_REQUEST'
export const VERIFY_MFA_TOKEN_RESPONSE                          = 'VERIFY_MFA_TOKEN_RESPONSE'
export const VERIFY_MFA_TOKEN_ERROR                             = 'VERIFY_MFA_TOKEN_ERROR'
export const REFRESH_ACCESS_TOKEN_REQUEST                       = 'REFRESH_ACCESS_TOKEN_REQUEST'
export const REFRESH_ACCESS_TOKEN_RESPONSE                      = 'REFRESH_ACCESS_TOKEN_RESPONSE'
export const REFRESH_ACCESS_TOKEN_ERROR                         = 'REFRESH_ACCESS_TOKEN_ERROR'
export const TOTP_CREATE_FACTOR_REQUEST                         = 'TOTP_CREATE_FACTOR_REQUEST'
export const TOTP_CREATE_FACTOR_RESPONSE_SUCCESS                = 'TOTP_CREATE_FACTOR_RESPONSE_SUCCESS'
export const TOTP_CREATE_FACTOR_RESPONSE_ERRORS                 = 'TOTP_CREATE_FACTOR_RESPONSE_ERRORS'
export const TOTP_VERIFY_FACTOR_REQUEST                         = 'TOTP_VERIFY_FACTOR_REQUEST'
export const TOTP_VERIFY_FACTOR_RESPONSE_SUCCESS                = 'TOTP_VERIFY_FACTOR_RESPONSE_SUCCESS'
export const TOTP_VERIFY_FACTOR_RESPONSE_ERRORS                 = 'TOTP_VERIFY_FACTOR_RESPONSE_ERRORS'
export const TOTP_FACTOR_RESET                                  = 'TOTP_FACTOR_RESET'
export const USER_MFA_ENTER                                     = 'USER_MFA_ENTER'
export const USER_MFA_SETUP                                     = 'USER_MFA_SETUP'
export const LOGOUT_REQUEST                                     = 'LOGOUT_REQUEST'
export const LOGOUT_RESPONSE                                    = 'LOGOUT_RESPONSE'
export const LOGGED_IN_USER_DETAILS_REQUEST                     = 'LOGGED_IN_USER_DETAILS_REQUEST'
export const LOGGED_IN_USED_DETAILS_RESPONSE                    = 'LOGGED_IN_USED_DETAILS_RESPONSE'
export const PASSWORD_INPUT_CHANGE                              = 'PASSWORD_INPUT_CHANGE'
export const COMFIRM_PASSWORD_INPUT_CHANGE                      = 'COMFIRM_PASSWORD_INPUT_CHANGE'
export const VERIFY_PREFLIGHT_REQUEST                           = 'VERIFY_PREFLIGHT_REQUEST'
export const VERIFY_PREFLIGHT_RESPONSE                          = 'VERIFY_PREFLIGHT_RESPONSE'
export const VERIFY_PREFLIGHT_ERROR                             = 'VERIFY_PREFLIGHT_ERROR'
export const PASSWORD_CHANGE_SUBMIT                             = 'PASSWORD_CHANGE_SUBMIT'
export const PASSWORD_CHANGE_SUCCESS                            = 'PASSWORD_CHANGE_SUCCESS'
export const PASSWORD_CHANGE_ERROR                              = 'PASSWORD_CHANGE_ERROR'
export const PASSWORD_RESET_ERRORS                              = 'PASSWORD_RESET_ERRORS'
export const PASSWORD_RESET_REQUEST_SUBMIT                      = 'PASSWORD_RESET_REQUEST_SUBMIT'
export const PASSWORD_RESET_REQUEST_SUCCESS                     = 'PASSWORD_RESET_REQUEST_SUCCESS'
export const PASSWORD_RESET_REQUEST_ERROR                       = 'PASSWORD_RESET_REQUEST_ERROR'
export const NOT_AUTHORISED_RESPONSE                            = 'NOT_AUTHORISED_RESPONSE'
export const LOG_REQUEST                                        = 'LOG_REQUEST'
export const FORCED_OUT                                         = 'FORCED_OUT'
export const INHERIT_AUTH_CREDENTIALS                           = 'INHERIT_AUTH_CREDENTIALS'
export const CHECK_USER_LOCKDOWN_STATUS_REQUEST                 = 'CHECK_USER_LOCKDOWN_STATUS_REQUEST'
export const CHECK_USER_LOCKDOWN_STATUS_RESPONSE                = 'CHECK_USER_LOCKDOWN_STATUS_RESPONSE'
export const RESET_LOGIN_COUNTER                                = 'RESET_LOGIN_COUNTER'
export const CAPTCHA_RECAPTCHA_REQUEST                          = 'CAPTCHA_RECAPTCHA_REQUEST'
export const CAPTCHA_RECAPTCHA_RESPONSE_ERRORS                  = 'CAPTCHA_RECAPTCHA_RESPONSE_ERRORS'
export const CAPTCHA_RECAPTCHA_RESPONSE_SUCCESS                 = 'CAPTCHA_RECAPTCHA_RESPONSE_SUCCESS'
export const CAPTCHA_RECAPTCHA_RESET_ERRORS                     = 'CAPTCHA_RECAPTCHA_RESET_ERRORS'
export const VIEWER_SELECT_TAB_PHOTOS                           = 'VIEWER_SELECT_TAB_PHOTOS'
export const VIEWER_SELECT_TAB_VIDEO                            = 'VIEWER_SELECT_TAB_VIDEO'
export const VIEWER_SELECT_TAB_CAMERAS                          = 'VIEWER_SELECT_TAB_CAMERAS'
export const VIEWER_SELECT_TAB_OPENSPACE                        = 'VIEWER_SELECT_TAB_OPENSPACE'
export const VIEWER_SELECT_TAB_WEATHERLINK                      = 'VIEWER_SELECT_TAB_WEATHERLINK'
export const VIEWER_SELECT_TAB_MAP                              = 'VIEWER_SELECT_TAB_MAP'
export const VIEWER_SELECT_TAB_PROJECTS                         = 'VIEWER_SELECT_TAB_PROJECTS'
export const VIEWER_LOAD_USER_REQUEST                           = 'VIEWER_LOAD_USER_REQUEST'
export const VIEWER_LOAD_USER_SUCCESS                           = 'VIEWER_LOAD_USER_SUCCESS'
export const VIEWER_USER_SUSPENDED                              = 'VIEWER_USER_SUSPENDED'
export const VIEWER_LOAD_USER_ERROR                             = 'VIEWER_LOAD_USER_ERROR'
export const VIEWER_RESET_USER                                  = 'VIEWER_RESET_USER'
export const VIEWER_SELECT_PROJECT                              = 'VIEWER_SELECT_PROJECT'
export const VIEWER_SELECT_PROJECT_AND_CAMERA                   = 'VIEWER_SELECT_PROJECT_AND_CAMERA'
export const VIEWER_SELECT_CAMERA                               = 'VIEWER_SELECT_CAMERA'
export const VIEWER_LOAD_PROJECT_THUMBS_REQUEST                 = 'VIEWER_LOAD_PROJECT_THUMBS_REQUEST'
export const VIEWER_LOAD_PROJECT_THUMBS_SUCCESS                 = 'VIEWER_LOAD_PROJECT_THUMBS_SUCCESS'
export const VIEWER_LOAD_PROJECT_THUMBS_ERROR                   = 'VIEWER_LOAD_PROJECT_THUMBS_ERROR'
export const VIEWER_LOAD_IMAGE_LIST_REQUEST                     = 'VIEWER_LOAD_IMAGE_LIST_REQUEST'
export const VIEWER_LOAD_IMAGE_LIST_RESPONSE                    = 'VIEWER_LOAD_IMAGE_LIST_RESPONSE'
export const VIEWER_LOAD_IMAGE_LIST_ERROR                       = 'VIEWER_LOAD_IMAGE_LIST_ERROR'
export const VIEWER_LOAD_CAMERA_DATES_REQUEST                   = 'VIEWER_LOAD_CAMERA_DATES_REQUEST'
export const VIEWER_LOAD_CAMERA_DATES_RESPONSE                  = 'VIEWER_LOAD_CAMERA_DATES_RESPONSE'
export const VIEWER_LOAD_CAMERA_DATES_ERROR                     = 'VIEWER_LOAD_CAMERA_DATES_ERROR'
export const VIEWER_SELECT_CAMERA_DATE                          = 'VIEWER_SELECT_CAMERA_DATE'
export const VIEWER_SELECT_CAMERA_TIME                          = 'VIEWER_SELECT_CAMERA_TIME'
export const VIEWER_SELECT_IMAGE                                = 'VIEWER_SELECT_IMAGE'
export const VIEWER_IMAGE_LOAD                                  = 'VIEWER_IMAGE_LOAD'
export const VIEWER_CLEAR_CURRENT_IMAGE                         = 'VIEWER_CLEAR_CURRENT_IMAGE'
export const VIEWER_CLEAR_IMAGE_LIST                            = 'VIEWER_CLEAR_IMAGE_LIST'
export const VIEWER_PHOTO_UPDATE_IMAGE_DIMENSIONS               = 'VIEWER_PHOTO_UPDATE_IMAGE_DIMENSIONS'
export const VIEWER_PHOTO_UPDATE_WINDOW_DIMENSIONS              = 'VIEWER_PHOTO_UPDATE_WINDOW_DIMENSIONS'
export const VIEWER_PHOTO_SET_PAN_AND_ZOOM                      = 'VIEWER_PHOTO_SET_PAN_AND_ZOOM'
export const VIEWER_PHOTO_SHOW_PAN_WINDOW                       = 'VIEWER_PHOTO_SHOW_PAN_WINDOW'
export const VIEWER_PHOTO_HIDE_PAN_WINDOW                       = 'VIEWER_PHOTO_HIDE_PAN_WINDOW'
export const VIEWER_PHOTO_PREVENT_HIDE_PAN_WINDOW               = 'VIEWER_PHOTO_PREVENT_HIDE_PAN_WINDOW'
export const VIEWER_PHOTO_ALLOW_HIDE_PAN_WINDOW                 = 'VIEWER_PHOTO_ALLOW_HIDE_PAN_WINDOW'
export const VIEWER_PHOTO_SHOW_DATETIME                         = 'VIEWER_PHOTO_SHOW_DATETIME'
export const VIEWER_PHOTO_HIDE_DATETIME                         = 'VIEWER_PHOTO_HIDE_DATETIME'
export const VIEWER_PHOTO_DOWNLOAD                              = 'VIEWER_PHOTO_DOWNLOAD'
export const VIEWER_TIMELAPSE_DOWNLOAD                          = 'VIEWER_TIMELAPSE_DOWNLOAD'
export const VIEWER_LOAD_WEATHER_REQUEST                        = 'VIEWER_LOAD_WEATHER_REQUEST'
export const VIEWER_LOAD_WEATHER_SUCCESS                        = 'VIEWER_LOAD_WEATHER_SUCCESS'
export const VIEWER_LOAD_WEATHER_ERROR                          = 'VIEWER_LOAD_WEATHER_ERROR'
export const VIEWER_CALENDAR_SET_CURRENT                        = 'VIEWER_CALENDAR_SET_CURRENT'
export const VIEWER_SET_ASSOCIATED_CAMERA                       = 'VIEWER_SET_ASSOCIATED_CAMERA'
export const VIEWER_ASSOCIATED_CAMERA_LOAD_DATES_REQUEST        = 'VIEWER_ASSOCIATED_CAMERA_LOAD_DATES_REQUEST'
export const VIEWER_ASSOCIATED_CAMERA_LOAD_DATES_RESPONSE       = 'VIEWER_ASSOCIATED_CAMERA_LOAD_DATES_RESPONSE'
export const VIEWER_ASSOCIATED_CAMERA_LOAD_DATES_ERROR          = 'VIEWER_ASSOCIATED_CAMERA_LOAD_DATES_ERROR'
export const VIEWER_ASSOCIATED_CAMERA_LOAD_IMAGE_LIST_REQUEST   = 'VIEWER_ASSOCIATED_CAMERA_LOAD_IMAGE_LIST_REQUEST'
export const VIEWER_ASSOCIATED_CAMERA_LOAD_IMAGE_LIST_RESPONSE  = 'VIEWER_ASSOCIATED_CAMERA_LOAD_IMAGE_LIST_RESPONSE'
export const VIEWER_ASSOCIATED_CAMERA_LOAD_IMAGE_LIST_ERROR     = 'VIEWER_ASSOCIATED_CAMERA_LOAD_IMAGE_LIST_ERROR'
export const VIEWER_ASSOCIATED_CAMERA_CLEAR_IMAGE_LIST          = 'VIEWER_ASSOCIATED_CAMERA_CLEAR_IMAGE_LIST'
export const VIEWER_COMPARE_ENABLE                              = 'VIEWER_COMPARE_ENABLE'
export const VIEWER_COMPARE_DISABLE                             = 'VIEWER_COMPARE_DISABLE'
export const VIEWER_COMPARE_CHANGE_TARGET                       = 'VIEWER_COMPARE_CHANGE_TARGET'
export const VIEWER_COMPARE_CHANGE_MODE                         = 'VIEWER_COMPARE_CHANGE_MODE'
export const VIEWER_COMPARE_CHANGE_WIPE_ORIENTATION             = 'VIEWER_COMPARE_CHANGE_WIPE_ORIENTATION'
export const VIEWER_COMPARE_CHANGE_WIPE_POSITION                = 'VIEWER_COMPARE_CHANGE_WIPE_POSITION'
export const VIEWER_COMPARE_CHANGE_OPACITY                      = 'VIEWER_COMPARE_CHANGE_OPACITY'
export const VIEWER_COMPARE_SELECT_DATE                         = 'VIEWER_COMPARE_SELECT_DATE'
export const VIEWER_COMPARE_SELECT_TIME                         = 'VIEWER_COMPARE_SELECT_TIME'
export const VIEWER_COMPARE_CALENDAR_SET_CURRENT                = 'VIEWER_COMPARE_CALENDAR_SET_CURRENT'
export const VIEWER_COMPARE_CALENDAR_HIDE                       = 'VIEWER_COMPARE_CALENDAR_HIDE'
export const VIEWER_COMPARE_CALENDAR_SHOW                       = 'VIEWER_COMPARE_CALENDAR_SHOW'
export const VIEWER_COMPARE_SET_DRAGGING                        = 'VIEWER_COMPARE_SET_DRAGGING'
export const VIEWER_COMPARE_REMOVE_DRAGGING                     = 'VIEWER_COMPARE_REMOVE_DRAGGING'
export const VIEWER_SELECT_COMPARE_IMAGE                        = 'VIEWER_SELECT_COMPARE_IMAGE'
export const VIEWER_CLEAR_COMPARE_IMAGE                         = 'VIEWER_CLEAR_COMPARE_IMAGE'
export const VIEWER_COMPARE_CLEAR_SELECTED_DATE_AND_TIME        = 'VIEWER_COMPARE_CLEAR_SELECTED_DATE_AND_TIME'
export const VIEWER_CLEAR_CALENDAR                              = 'VIEWER_CLEAR_CALENDAR'
export const VIEWER_CLEAR_COMPARE_CALENDAR                      = 'VIEWER_CLEAR_COMPARE_CALENDAR'
export const VIEWER_COMPARE_SHOW_NO_BIM_MESSAGE                 = 'VIEWER_COMPARE_SHOW_NO_BIM_MESSAGE'
export const VIEWER_COMPARE_HIDE_NO_BIM_MESSAGE                 = 'VIEWER_COMPARE_HIDE_NO_BIM_MESSAGE'
export const VIEWER_SET_IS_MODEL_STATE                          = 'VIEWER_SET_IS_MODEL_STATE'
export const VIEWER_ASSOCIATE_MODELS_AND_CAMERAS                = 'VIEWER_ASSOCIATE_MODELS_AND_CAMERAS'
export const VIEWER_ASSOCIATED_CAMERA_RESET                     = 'VIEWER_ASSOCIATED_CAMERA_RESET'
export const VIEWER_COMPARE_SHOW_CALENDAR_POPOUT                = 'VIEWER_COMPARE_SHOW_CALENDAR_POPOUT'
export const VIEWER_COMPARE_HIDE_CALENDAR_POPOUT                = 'VIEWER_COMPARE_HIDE_CALENDAR_POPOUT'
export const VIEWER_COMPARE_SHOW_COMPARE_CALENDAR_POPOUT        = 'VIEWER_COMPARE_SHOW_COMPARE_CALENDAR_POPOUT'
export const VIEWER_COMPARE_HIDE_COMPARE_CALENDAR_POPOUT        = 'VIEWER_COMPARE_HIDE_COMPARE_CALENDAR_POPOUT'
export const VIEWER_COMPARE_SELECT_TIMELINE                     = 'VIEWER_COMPARE_SELECT_TIMELINE'
export const VIEWER_CLEAR_SELECTED_TAB                          = 'VIEWER_CLEAR_SELECTED_TAB'
export const VIEWER_SELECT_TAB_SETTINGS                         = 'VIEWER_SELECT_TAB_SETTINGS'
export const VIEWER_SET_CAMERA_DATES                            = 'VIEWER_SET_CAMERA_DATES'
export const VIEWER_TOGGLE_FULLSCREEN                           = 'VIEWER_TOGGLE_FULLSCREEN'
export const VIEWER_ENABLE_AUTO_REFRESH                         = 'VIEWER_ENABLE_AUTO_REFRESH'
export const VIEWER_DISABLE_AUTO_REFRESH                        = 'VIEWER_DISABLE_AUTO_REFRESH'
export const VIEWER_SHOW_TRACKING_CONSENT                       = 'VIEWER_SHOW_TRACKING_CONSENT'
export const VIEWER_HIDE_TRACKING_CONSENT                       = 'VIEWER_HIDE_TRACKING_CONSENT'
export const SETTINGS_RESET                                     = 'SETTINGS_RESET'
export const SETTINGS_CHANGE_PASSWORD_REQUEST                   = 'SETTINGS_CHANGE_PASSWORD_REQUEST'
export const SETTINGS_CHANGE_PASSWORD_RESPONSE_ERRORS           = 'SETTINGS_CHANGE_PASSWORD_RESPONSE_ERRORS'
export const SETTINGS_CHANGE_MFA_REQUEST                        = 'SETTINGS_CHANGE_MFA_REQUEST'
export const SETTINGS_CHANGE_MFA_RESPONSE_ERRORS                = 'SETTINGS_CHANGE_MFA_RESPONSE_ERRORS'
export const SETTINGS_CHANGE_MFA_RESPONSE_SUCCESS               = 'SETTINGS_CHANGE_MFA_RESPONSE_SUCCESS'
export const SETTINGS_CHANGE_PASSWORD_RESPONSE_SUCCESS          = 'SETTINGS_CHANGE_PASSWORD_RESPONSE_SUCCESS'
export const SETTINGS_VALIDATE_PASSWORD_REQUEST                 = 'SETTINGS_VALIDATE_PASSWORD_REQUEST'
export const SETTINGS_VALIDATE_PASSWORD_RESPONSE_ERRORS         = 'SETTINGS_VALIDATE_PASSWORD_RESPONSE_ERRORS'
export const SETTINGS_VALIDATE_PASSWORD_RESPONSE_SUCCESS        = 'SETTINGS_VALIDATE_PASSWORD_RESPONSE_SUCCESS'
export const SETTINGS_GET_QR_CODE_ERRORS                        = 'SETTINGS_GET_QR_CODE_ERRORS'
export const SETTINGS_GET_QR_CODE_SUCCESS                       = 'SETTINGS_GET_QR_CODE_SUCCESS'
export const CARDS_SET_CARD_MODE_ACTIVE                         = 'CARDS_SET_CARD_MODE_ACTIVE'
export const CARDS_HIDE_MENU                                    = 'CARDS_HIDE_MENU'
export const CARDS_SHOW_MENU                                    = 'CARDS_SHOW_MENU'
export const CARDS_SET_SECONDARY_MENU                           = 'CARDS_SET_SECONDARY_MENU'
export const CARDS_CLEAR_SECONDARY_MENU                         = 'CARDS_CLEAR_SECONDARY_MENU'
export const VIDEO_EDIT_RESET                                   = 'VIDEO_EDIT_RESET';
export const VIDEO_EDIT_UPDATE                                  = 'VIDEO_EDIT_UPDATE'
export const VIDEO_EDIT_GET_DAYS_AND_TIMES_REQUEST              = 'VIDEO_EDIT_GET_DAYS_AND_TIMES_REQUEST'
export const VIDEO_EDIT_GET_DAYS_AND_TIMES_ERRORS               = 'VIDEO_EDIT_GET_DAYS_AND_TIMES_ERRORS'
export const VIDEO_EDIT_GET_DAYS_AND_TIMES_SUCCESS              = 'VIDEO_EDIT_GET_DAYS_AND_TIMES_SUCCESS'
export const VIDEO_EDIT_GET_MATCHING_IMAGE_COUNT_REQUEST        = 'VIDEO_EDIT_GET_MATCHING_IMAGE_COUNT_REQUEST'
export const VIDEO_EDIT_GET_MATCHING_IMAGE_COUNT_ERRORS         = 'VIDEO_EDIT_GET_MATCHING_IMAGE_COUNT_ERRORS'
export const VIDEO_EDIT_GET_MATCHING_IMAGE_COUNT_SUCCESS        = 'VIDEO_EDIT_GET_MATCHING_IMAGE_COUNT_SUCCESS'
export const VIDEO_EDIT_SUBMIT_NEW_EDIT_REQUEST                 = 'VIDEO_EDIT_SUBMIT_NEW_EDIT_REQUEST'
export const VIDEO_EDIT_SUBMIT_NEW_EDIT_RESPONSE                = 'VIDEO_EDIT_SUBMIT_NEW_EDIT_RESPONSE'
export const VIDEO_EDIT_SUBMIT_NEW_EDIT_ERRORS                  = 'VIDEO_EDIT_SUBMIT_NEW_EDIT_ERRORS'
export const VIDEO_EDIT_PERMIT_NEXT                             = 'VIDEO_EDIT_PERMIT_NEXT'
export const VIDEO_EDIT_DENY_NEXT                               = 'VIDEO_EDIT_DENY_NEXT'
export const VIEWPORT_UPDATE                                    = 'VIEWPORT_UPDATE'
export const SET_LAT_LONG                                       = 'SET_LAT_LONG'
