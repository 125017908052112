import * as types from './actionTypes'
import { cameraModelAssociation } from './compareActions'
import { disableAutoRefresh } from './photoViewerActions'
export const PROJECTS = 'projects'
export const CAMERAS = 'cameras'
export const PHOTOS = 'photos'
export const VIDEO = 'video'
export const SETTINGS = 'settings'
export const OPENSPACE = 'openspace'
export const WEATHERLINK = 'weatherlink'
export const MAP = 'map'

export function selectProjectsTab() {
    return { type: types.VIEWER_SELECT_TAB_PROJECTS}
}
export function selectPhotosTab() {
    return { type: types.VIEWER_SELECT_TAB_PHOTOS}
}

export function selectVideoTab() {
    return { type: types.VIEWER_SELECT_TAB_VIDEO}
}

export function selectCamerasTab() {
    return { type: types.VIEWER_SELECT_TAB_CAMERAS}
}

export function selectWeatherLinkTab() {
    return { type: types.VIEWER_SELECT_TAB_WEATHERLINK}
}

export function selectMapTab() {
    return {type: types.VIEWER_SELECT_TAB_MAP}
}

export function selectSettingsTab() {
    return { type: types.VIEWER_SELECT_TAB_SETTINGS}
}

export function clearSelectedTab() {
    return { type: types.VIEWER_CLEAR_SELECTED_TAB }
}

export function selectProject(projectIndex = null) {
    return (dispatch, getState) => {
        dispatch({
            type: types.VIEWER_SELECT_PROJECT,
            projectIndex: projectIndex
        })
    }
}

export function selectCamera(cameraIndex = null) {
    return (dispatch, getState) => {
        dispatch({
            type: types.VIEWER_SELECT_CAMERA,
            cameraIndex: cameraIndex
        })
    }
}

export function selectProjectAndCamera(projectIndex, cameraIndex) {
    return (dispatch, getState) => {
        const state = getState()
        projectIndex = parseInt(projectIndex, 10)
        cameraIndex = parseInt(cameraIndex, 10)
        // Shouldn't happen, but just in case
        if (isNaN(projectIndex)) {
            projectIndex = 0
        }
        if (isNaN(cameraIndex)) {
            cameraIndex = 0
        }
        const currentProject = state.navigation.currentProject
        const currentCamera = state.navigation.currentCamera
        if (projectIndex !== currentProject || cameraIndex !== currentCamera) {
            dispatch({
                type: types.VIEWER_SELECT_PROJECT_AND_CAMERA,
                projectIndex: projectIndex,
                cameraIndex: cameraIndex
            })
            dispatch(disableAutoRefresh())
        }
        if (('projects' in state.user) && (state.user.projects[projectIndex])) {
            // Check associations to see if there is a model for this camera
            dispatch(cameraModelAssociation(state.user.projects[projectIndex], cameraIndex))
            // If camera has no end date, trigger an error
            const endDate = state.user.projects[projectIndex].cameras[cameraIndex].end
            if (endDate) {
                dispatch({type: types.VIEWER_LOAD_CAMERA_DATES_RESPONSE})
            } else {
                dispatch({
                    type: types.VIEWER_LOAD_CAMERA_DATES_ERROR,
                    errors: 'Unable to load any dates for this camera'
                })
            }
        }
    }
}

export function enableCardMode() {
    return {
        type: types.CARDS_SET_CARD_MODE_ACTIVE
    }
}

export function hideMenu() {
  return {
    type: types.CARDS_HIDE_MENU,
  };
}

export function showMenu() {
    return {
        type: types.CARDS_SHOW_MENU,
    };
}

export function toggleMenu() {
    return (dispatch, getState) => {
        const {navigation} = getState();
        if (navigation.menuVisible) {
            dispatch(
                {
                    type: types.CARDS_HIDE_MENU,
                }
            );
        } else {
            dispatch(
                {
                    type: types.CARDS_SHOW_MENU,
                }
            );
        }
    };
}

export function setSecondaryMenu(key) {
    return {
        type: types.CARDS_SET_SECONDARY_MENU,
        secondaryMenu: key,
    };
}

export function clearSecondaryMenu() {
    return {
        type: types.CARDS_CLEAR_SECONDARY_MENU,
    };
}
