import React, { Component, Suspense } from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import CacheBuster from 'react-cache-buster';
import packageInfo from '../../package.json';
import Loginless from './Loginless'
import Loading from './Loading'

const AuthAs = React.lazy(() => import('./pages/AuthAs'))
const Viewer = React.lazy(() => import('./Viewer'))
const Login = React.lazy(() => import('./pages/Login'))
const LoginVia = React.lazy(() => import('./pages/LoginVia'))
const Logout = React.lazy(() => import('./Logout'))
const CardLogin = React.lazy(() => import('./pages/card/Login'))
const Card = React.lazy(() => import('./card/Card'))
const Suspended = React.lazy(() => import('./pages/Suspended'))
const ResetPassword = React.lazy(() => import('./pages/ResetPassword'))
const Verify = React.lazy(() => import('./pages/Verify'))
const PrivacyPolicy = React.lazy(() => import('./pages/PrivacyPolicy'))

class App extends Component {
    render() {
        const isProduction = process.env.NODE_ENV === 'production';
        return (
            <CacheBuster
                currentVersion={packageInfo.version}
                isEnabled={isProduction}
            >
                <Suspense fallback={<Loading />}>
                    <BrowserRouter>
                        <Switch>
                            <Route path='/viewer' render={() => <Viewer />} />
                            <Route path='/login/:step?' render={() => <Login />} />
                            <Route path='/loginvia' render={() => <LoginVia />} />
                            <Route path='/logout' render={() => <Logout />} />
                            <Route path='/card/login' render={() => <CardLogin />} />
                            <Route path='/card/:projectIndex' render={() => <Card />} />
                            <Route path='/card' render={() => <Card />} />
                            <Route path='/auth-as' render={() => <AuthAs />} />
                            <Route path='/suspended' render={() => <Suspended />} />
                            <Route exact path='/verify/hash/:hash/token/:token/:step?' render={() => <Verify />}/>
                            <Route exact path='/verify/hash/:hash/:step?'  render={() => <Verify />}/>
                            <Redirect from="/verify" to="/" />
                            <Route exact path='/resetPassword/hash/:hash/token/:token' render={() => <ResetPassword />}/>
                            <Route exact path='/resetPassword/hash/:hash' render={() => <ResetPassword />}/>
                            <Route path='/resetPassword' render={() => <ResetPassword />}/>
                            <Route path='/privacy' render={() => <PrivacyPolicy />} />
                            <Route path='/home'  render={() => <Redirect to='/viewer'/>} />
                            <Route exact path='/' render={() => <Redirect to='/viewer'/>} />
                            <Route path='/:username' render={() => <Loginless />} />
                        </Switch>
                    </BrowserRouter>
                </Suspense>
            </CacheBuster>
        )
    }
}

export default App
