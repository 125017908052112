export default {
    associatedCamera: {
        dates: {
            loading: false,
            success: false,
            errors: false,
            start: null,
            end: null,
            missing: []
        },
        imageList: {
            loading: false,
            success: false,
            errors: {},
            images: []
        },
        selectedDate: undefined,
        selectedTime: undefined
    },
    auth: {
        force_out: false,
        loading: false,
        refreshingAccessToken: false,
        errors: {
        },
        attempts: 0,
        mfa_number: null,
        totp: null,
        totpUrl: null
    },
    captcha: {
        isHuman: false,
        loading: false,
        errors: {},
        success: false,
        error: false,
        errors: {},
        mfaRequirement: null,
        enrolled: false
    },
    calendar: {
        date: null,
        month: null,
        year: null,
        popOutVisible: false
    },
    camera: {
        associatedCamera: [],
        isModel: false,
        dates: {
            loading: false,
            success: false,
            errors: false,
            start: null,
            end: null,
            missing: []
        },
        imageList: {
            loading: false,
            success: false,
            errors: {},
            images: []
        },
        selectedDate: undefined,
        selectedTime: undefined
    },
    compare: {
        enabled: false,
        withSelf: true,
        with3d: false,
        mode: 'COMPARE_WIPE',
        opacity: 0.5,
        horizontalWipe: true,
        wipePosition: 0.5,
        selectedDate: null,
        selectedTime: null,
        selectedTimeline: 0,
        showNoBIMMessage: false,
        showCalendar: true,
        cameraModelAssociations: {},
        modelCameraAssociations: {}
    },
    compareCalendar: {
        date: null,
        month: null,
        year: null,
        popOutVisible: false
    },
    compareImage: {
        width: 0,
        height: 0,
        time: null,
        date: null,
        filename: null,
        imageURL: null,
        thumbnailURL: null
    },
    currentImage: {
        width: 0,
        height: 0,
        time: null,
        filename: null,
        imageURL: null,
        thumbnailURL: null
    },
    comparisionImage: {
        width: 0,
        height: 0,
        time: null,
        filename: null,
        imageURL: null,
        thumbnailURL: null
    },
    log: {
        logs: []
    },
    navigation: {
        currentTab: 'projects',
        returnToTab: 'projects',
        currentProject: null,
        currentCamera: null,
        cardMode: false,
        menuVisible: false,
        secondaryMenu: null
    },
    password: {
        strength: 0,
        match: false,
        warning: null,
        suggestions: [],
        errors: []
    },
    photoViewer: {
        dateTimeVisible: false,
        fullscreen: false,
        autoRefreshEnabled: false
    },
    photoDimensions: {
        minScale: 1,
        scale: 1,
        panX: 0,
        panY: 0,
        offsetX: 0,
        offsetY: 0,
        initialOffsetX: null,
        initialOffsetY: null,
        displayWidth: null,
        displayHeight: null,
        imageWidth: null,
        imageHeight: null,
        smoothTransition: false,
        zoomed: false,
        zoomedAllTheWayIn: false,
    },
    panWindow: {
        panWindowVisible: false,
        preventHidePanWindow: false
    },
    user: {
        loading: false,
        success: false,
        errors: {},
        loginless: false,
        client: {},
        projects: [],
        projectsLoaded: false,
        settings: {},
        showTrackingConsent: false,
        mfa: {
            enabled: false,
            numberSet: false,
            authAs: false
        },
        role: null,
        roles_id: null,
    },
    weather: {
        loading: false,
        success: false,
        errors: {},
        hours: []
    },
    settings: {
        change_password: {
            processing: false,
            success: false,
            error: false,
            errors: {},
            details: {}
        },
        change_mfa: {
            processing: false,
            success: false,
            error: false,
            errors: {},
            details: {},
            mfa_type: null,
        },
        validate_password: {
            processing: false,
            success: false,
            error: false,
            errors: {},
            details: {}
        },
        mfa_qr_code: {
            processing: false,
            success: false,
            error: false,
            errors: {},
            url: null
        }
    },
    videoEdit: {
        loading: false,
        error: false,
        errors: {},
        count: undefined,
        projectIndex: undefined,
        cameraIndex: undefined,
        days: [],
        times: {},
        timecode: true,
        minWidth: undefined,
        minHeight: undefined,
        permitNext: true
    }
}
