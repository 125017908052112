import initialState from './initialState'
import {
    VIEWER_CALENDAR_SET_CURRENT,
    VIEWER_CLEAR_CALENDAR,
    VIEWER_COMPARE_SHOW_CALENDAR_POPOUT,
    VIEWER_COMPARE_HIDE_CALENDAR_POPOUT,
    LOGIN_RESPONSE
} from '../actions/actionTypes'

export default function calendar(state = initialState.calendar, action) {

    switch (action.type) {
        case VIEWER_CALENDAR_SET_CURRENT:
            return Object.assign(
                {},
                state,
                action.current
            )
        case VIEWER_CLEAR_CALENDAR:
            return initialState.calendar
        case VIEWER_COMPARE_SHOW_CALENDAR_POPOUT:
            return Object.assign(
                {},
                state,
                {
                    popOutVisible: true
                }
            )
        case VIEWER_COMPARE_HIDE_CALENDAR_POPOUT:
            return Object.assign(
                {},
                state,
                {
                    popOutVisible: false
                }
            )
        case LOGIN_RESPONSE:
            return initialState.calendar
        default:
            return state
    }
}
