import { combineReducers} from 'redux'
import associatedCamera from './associatedCameraReducer'
import auth from './authReducer'
import captcha from './captchaReducer'
import calendar from './calendarReducer'
import camera from './cameraReducer'
import compare from './compareReducer'
import compareCalendar from './compareCalendarReducer'
import compareImage from './compareImageReducer'
import currentImage from './currentImageReducer'
import log from './logReducer'
import navigation from './navigationReducer'
import panWindow from './panWindowReducer'
import password from './passwordReducer'
import photoDimensions from './photoDimensionsReducer'
import photoViewer from './photoViewerReducer'
import weather from './weatherReducer'
import user from './userReducer'
import settings from './settingsReducer'
import videoEdit from './videoEditReducer'

const rootReducer = combineReducers({
    auth,
    associatedCamera,
    captcha,
    calendar,
    camera,
    compare,
    compareCalendar,
    compareImage,
    currentImage,
    log,
    navigation,
    panWindow,
    password,
    photoDimensions,
    photoViewer,
    weather,
    user,
    settings,
    videoEdit
})

export default rootReducer
