import * as types from './actionTypes'
import { API_BASE_URL } from '../config'
import { fetchWithToken } from '../util/accessToken'
import { loadAnalytics } from '../util/analytics'
import { loadDatesForAllCameras } from './cameraActions'
import { notAuthorisedResponse } from './authActions'

export function loadUser(history, match = {}) {
    return (dispatch, getState) => {
        dispatch({ type: types.VIEWER_LOAD_USER_REQUEST })
        return fetchWithToken(
             `${API_BASE_URL}/api/viewer/user?includeLatest=true&includeVideo=true`,
             {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        ).then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    dispatch(notAuthorisedResponse())
                }  else {
                    response.json().then(data => {
                        if (response.status === 402){
                            dispatch ({
                                type: types.VIEWER_USER_SUSPENDED,
                                message: data.message
                            })
                            history.push('/suspended')
                        } else if (data.errors) {
                            dispatch(
                                {
                                    type: types.VIEWER_LOAD_USER_ERROR,
                                    errors: data.errors
                                }
                            )
                        } else {
                            dispatch(
                                {
                                    type: types.VIEWER_LOAD_USER_ERROR,
                                    errors: {
                                        unknownErrorType: {
                                            unknownError: "There was an unexpected error."
                                        }
                                    }
                                }
                            )
                        }
                    })
                }
            } else {
                response.json().then(data => {
                    if (data.errors) {
                        dispatch({
                            type: types.VIEWER_LOAD_USER_ERROR,
                            errors: data.errors
                        })
                    } else {
                        dispatch({
                            type: types.VIEWER_LOAD_USER_SUCCESS,
                            user: data
                        })
                        if (!data.loginless) {
                            // Set Google Analytics tracking User ID
                            if (data.id) {
                                loadAnalytics(
                                    data.id,
                                    history.location.pathname,
                                    data.projects
                                )
                            }
                            dispatch({
                                type: types.VIEWER_HIDE_TRACKING_CONSENT
                            })
                        } else {
                            // Cookie banner is only used on loginless users
                            // wamt to test removing the cookie window for the iframes

                            // Check cookie set
                            // eslint-disable-next-line no-useless-escape
                            // if (document.cookie.replace(/(?:(?:^|.*;\s*)trackingConsent\s*\=\s*([^;]*).*$)|^.*$/, "$1") === "true") {
                            //     if (data.id) {
                            //         loadAnalytics(
                            //             data.id,
                            //             history.location.pathname,
                            //             data.projects
                            //         )
                            //     }
                                dispatch({
                                     type: types.VIEWER_HIDE_TRACKING_CONSENT
                                })
                            // } else {
                            //     dispatch({
                            //         type: types.VIEWER_SHOW_TRACKING_CONSENT
                            //     })
                            // }
                        }
                        dispatch(loadDatesForAllCameras(data.projects, history))
                    }
                })
            }
        })
        .catch((response) => {
            dispatch(
                {
                    type: types.VIEWER_LOAD_USER_ERROR,
                    errors: {
                        unknownErrorType: {
                            unknownError: "There was an unexpected error"
                        }
                    }
                }
            )
        })
    }
}

export function setCameraDates(project, camera, data) {
    return {
        type: types.VIEWER_SET_CAMERA_DATES,
        project: project,
        camera: camera,
        start: data.start,
        end: data.end,
        missing: data.missing
    }
}

export function loadProjectThumbnails(history) {
    return (dispatch, getState) => {
        dispatch({ type: types.VIEWER_LOAD_PROJECT_THUMBS_REQUEST })
        return fetchWithToken(
             `${API_BASE_URL}/api/viewer/imageList/latest`,
             {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        ).then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    dispatch(notAuthorisedResponse())
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.VIEWER_LOAD_PROJECT_THUMBS_ERROR,
                                    errors: data.errors
                                }
                            )
                        } else {
                            dispatch(
                                {
                                    type: types.VIEWER_LOAD_PROJECT_THUMBS_ERROR,
                                    errors: {
                                        unknownErrorType: {
                                            unknownError: "There was an unexpected error."
                                        }
                                    }
                                }
                            )
                        }
                    })
                }
            } else {
                response.json().then(data => {
                    if (data.errors) {
                        dispatch(
                            {
                                type: types.VIEWER_LOAD_PROJECT_THUMBS_ERROR,
                                errors: data.errors
                            }
                        )
                    } else {
                        dispatch(
                            {
                                type: types.VIEWER_LOAD_PROJECT_THUMBS_SUCCESS,
                                thumbnails: data
                            }
                        )
                    }
                })
            }
        })
        .catch((response) => {
            dispatch(
                {
                    type: types.VIEWER_LOAD_PROJECT_THUMBS_ERROR,
                    errors: {
                        unknownErrorType: {
                            unknownError: "There was an unexpected error"
                        }
                    }
                }
            )
        })
    }
}

export function acceptTracking() {
    return (dispatch, getState) => {
        // Set cookie
        document.cookie = "trackingConsent=true; expires=Fri, 31 Dec 9999 23:59:59 GMT"
        const state = getState()
        let id, page, projectsList = null
        if (state.user) {
            if (state.user.id) {
                id = state.user.id
            }
            if (state.user.projects) {
                projectsList = state.user.projects
            }
        }
        if (window.location) {
            page = window.location.pathname
        }
        loadAnalytics(id, page, projectsList)
        dispatch(
            {
                type: types.VIEWER_HIDE_TRACKING_CONSENT
            }
        )
    }
}
