// eslint-disable-next-line
import React, {Component} from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import * as authActions from '../actions/authActions'

class Loginless extends Component {

    componentDidMount() {
        if (!this.props.match) {
            // No matching URL to extract username from
            this.props.history.push('/')
        }
        const username = this.props.match.params['username']
        this.props.authActions.loginlessRequest(this.props.history, username)
    }

    render() {
        return null
    }
}

function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(authActions, dispatch)
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Loginless))
