// Polyfills for IE Compatibility
import 'core-js/es/map'
import 'core-js/es/set'
import 'react-app-polyfill/ie11';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux'
import configureStore from './store/configureStore'
import App from './components/App'
import 'semantic-ui-css/semantic.min.css'

const element = document.getElementById('app')
const root = ReactDOM.createRoot(element)
const store = configureStore()

root.render(<Provider store={store}><App /></Provider>)
