import moment from 'moment'

export function applyConstraints(panX, panY, scale, correctX, correctY,
    displayWidth, displayHeight, imageWidth, imageHeight, zoomPermitted) {
    correctX = correctX === undefined ? 0 : correctX
    correctY = correctY === undefined ? 0 : correctY

    // Calculate optimal scale
    const minScale = displayWidth / displayHeight > imageWidth / imageHeight
        ? displayHeight / imageHeight
        : displayWidth / imageWidth
    const maxScale = zoomPermitted ? 4.0 : minScale

    // Constrain zoom level
    if (scale <= minScale) {
        scale = minScale
    } else if (scale > maxScale) {
        scale = maxScale
    } else {
        panX -= correctX
        panY -= correctY
    }

    // Calculate pan constraints
    let minX, minY, maxX, maxY

    if (imageWidth * scale <= displayWidth) {
        minX = (displayWidth - imageWidth * scale) / 2 / scale
        maxX = minX
    } else {
        maxX = 0
        minX = (displayWidth - imageWidth * scale) / scale
    }

    if (imageHeight * scale <= displayHeight) {
        minY = (displayHeight - imageHeight * scale) / 2 / scale
        maxY = minY
    } else {
        maxY = 0
        minY = (displayHeight - imageHeight * scale) / scale
    }
    // Constrain panX
    if (panX < minX) {
        panX = minX
    } else if (panX > maxX) {
        panX = maxX
    }

    // Constrain panY
    if (panY < minY) {
        panY = minY
    } else if (panY > maxY) {
        panY = maxY
    }

    return {
        panX: panX,
        panY: panY,
        scale: scale,
        minScale: minScale,
        maxScale: maxScale,
        zoomed: ( scale / 1 ) > minScale,
        zoomedAllTheWayIn: scale > ( maxScale / 1.05 )
    }
}

export function getSelectedPositionFromList(camera, time) {
    for (var pos=0; pos < camera.imageList.images.length; pos++) {
        const image = camera.imageList.images[pos]
        if (time === image.time) {
            return pos
        }
    }
    return false
}

export function getSelectedPositionFromListByFilename(camera, filename) {
  for (let pos=0; pos < camera.imageList.images.length; pos++) {
    const image = camera.imageList.images[pos];
    if (filename === image.image) {
      return pos;
    }
  }
  return false;
}

export function getPreviousDate(dates, selectedDate) {
    if (!dates || !("start" in dates) || !("missing" in dates)) {
        return false
    }
    do {
        selectedDate = moment(selectedDate, 'YYYY-MM-DD').subtract(1, 'days').format('YYYY-MM-DD')
    } while (dates.missing.indexOf(selectedDate) !== -1)
    if (moment(selectedDate, 'YYYY-MM-DD') < moment(dates.start, 'YYYY-MM-DD')) {
        // Can't go later than end date
        return false
    } else {
        return selectedDate
    }
}

export function getNextDate(dates, selectedDate) {
    if (!dates || !("end" in dates) || !("missing" in dates)) {
        return false
    }
    do {
        selectedDate = moment(selectedDate, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD')
    } while (dates.missing.indexOf(selectedDate) !== -1)
    if (moment(selectedDate, 'YYYY-MM-DD') > moment(dates.end, 'YYYY-MM-DD')) {
        // Can't go later than end date
        return false
    } else {
        return selectedDate
    }
}
