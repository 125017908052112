import * as types from './actionTypes'
import { API_BASE_URL}  from '../config'
import { log } from './logActions'
import { fetchWithToken } from '../util/accessToken'

export function notAuthorisedResponse() {
    return { type: types.NOT_AUTHORISED_RESPONSE }
}

export function forcedOut() {
    return dispatch => {
        dispatch({type: types.FORCED_OUT })
    }
}

export function loginResponse(history, payload) {

    return dispatch => {
        if (payload.access_token) {
            dispatch(loginSuccess(history, payload))
        } else {
            dispatch(loginErrors(payload))
        }
    }
}

export function loginSuccess(history, payload) {
    return (dispatch, getState) => {
        dispatch( {type: types.LOGIN_SUCCESS, auth: payload} )
        if (!payload.mfa_verified && payload.mfa_required) {
            history.push('/login/setup-token')
            dispatch( { type: types.USER_MFA_SETUP })
        } else if (payload.mfa_required && payload.mfa_verified) {
            history.push('/login/enter-token')
            dispatch( { type: types.USER_MFA_ENTER })
        }
        else if (payload.access_token) {
            const { navigation } = getState();
            if (navigation.cardMode) {
                if (navigation.currentProject) {
                  history.push(`/card/projects/${navigation.currentProject}/cameras/0/image`)
                } else {
                  history.push('/card')
                }
            } else {
                history.push('/viewer/')
            }
        }
    }
}

export function loginErrors(payload) {
    return { type: types.LOGIN_ERRORS, auth: payload }
}

export function loginResetErrors() {
    return { type: types.LOGIN_RESET_ERRORS }
}

export function loginRequest(history, username, password) {
    return dispatch => {
        dispatch( { type: types.LOGIN_REQUEST,
            auth:
                {
                    username: username,
                    password: password,
                    loading: true
                }
        })
        return fetch(API_BASE_URL +  '/auth/authorize', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                'grant_type' : 'password',
                'application' : 'viewer',
                'username' : username,
                'password' : password
            })
        })
        .then(response => response.json())
        .then((payload) => {
            dispatch(loginResponse(history, payload))
        })
        .catch((response) => {
            dispatch(loginErrors(
                {
                    message: 'Unable to login. Please try again'
                }
            ))
            dispatch(log("error", response))
            dispatch(resetLoginCounter()) // Prevents Captcha-related issues
            dispatch(({ type: types.LOGIN_RESPONSE })) // prevents spinner
        })
    };
}

export function requestMfaToken(details) {
    return (dispatch, getState) => {
        dispatch( { type: types.SEND_MFA_TOKEN_REQUEST })
        let status
        let url = API_BASE_URL +  '/auth/send-mfa-token'
       
        return fetchWithToken(
            url,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(details)
            },
            dispatch,
            getState
        )
        .then(response => {
            status = response.status
            return response.json()
        })
        .then((json) => {
            if (status ===  201) {
                dispatch({
                    type: types.SEND_MFA_TOKEN_RESPONSE,
                    data: json
                })
            } else {
                dispatch({
                    type: types.SEND_MFA_TOKEN_ERROR,
                    errors: json
                })
            }
        })
        .catch((response) => {
            dispatch({
                type: types.SEND_MFA_TOKEN_ERROR,
                errors: {'message':'An unknown error occurred. Please try again.'}
            })
        })
    };
}

export function verifyMfaToken(details, history) {
    return (dispatch, getState) => {
        dispatch( { type: types.VERIFY_MFA_TOKEN_REQUEST })
        let status
        return fetchWithToken(
            API_BASE_URL +  '/auth/verify-mfa-token',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Accept': 'application/json'
                },
                body: JSON.stringify(details)
            },
            dispatch,
            getState
        )
        .then(response => {
            status = response.status
            return response.json()
        })
        .then((json) => {
            if (status ===  200) {
                dispatch({
                    type: types.VERIFY_MFA_TOKEN_RESPONSE,
                    data: json
                })
                const { navigation } = getState();
                if (navigation.cardMode) {
                    if (navigation.currentProject) {
                      history.push(`/card/projects/${navigation.currentProject}/cameras/0/image`)
                    } else {
                      history.push('/card')
                    }
                } else {
                    history.push('/viewer')
                }
            } else if (status === 403){
                dispatch({
                    type: types.VERIFY_MFA_TOKEN_ERROR,
                    errors: {'message': 'Sorry, the code was incorrect'}
                })
            } else {
                dispatch({
                    type: types.VERIFY_MFA_TOKEN_ERROR,
                    errors: {'message': json.error}
                })
            }
        })
        .catch((response) => {
            dispatch({
                type: types.SEND_MFA_TOKEN_ERROR,
                errors: {'message':'An unknown error occurred. Please try again.'}
            })
        })
    };
}

export function createTotpFactor(details) {
    return (dispatch, getState) => {
        dispatch({type: types.TOTP_CREATE_FACTOR_REQUEST})

        return fetchWithToken(
            `${API_BASE_URL}/auth/create-totp-factor`,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(details)
            },
            dispatch,
            getState
        ).then(response => {
            if (!response.ok) {
                response.json().then(data => {
                    if (data.errors) {
                        dispatch(
                            {
                                type: types.TOTP_CREATE_FACTOR_RESPONSE_ERRORS,
                                errors: data.errors
                            }
                        )
                    } else {
                        dispatch(
                            {
                                type: types.TOTP_CREATE_FACTOR_RESPONSE_ERRORS,
                                errors: {
                                    unknownError: "There was an unexpected error"
                                }
                            }
                        )
                    }
                })
            } else {
                response.json().then(data => {
                    if (data.errors) {
                        dispatch(
                            {
                                type: types.TOTP_CREATE_FACTOR_RESPONSE_ERRORS,
                                errors: data.errors
                            }
                        )
                    } else {
                        dispatch({
                            type: types.TOTP_CREATE_FACTOR_RESPONSE_SUCCESS,
                            details: data
                        })
                    }
                })

            }
        })
        .catch((response) => {
            dispatch(
                {
                    type: types.TOTP_CREATE_FACTOR_RESPONSE_ERRORS,
                    errors: {
                        unknownError: "There was an unexpected error"
                    }
                }
            )
        })
    }
}

export function verifyTotpFactor(details) {
    return (dispatch, getState) => {
        dispatch({type: types.TOTP_VERIFY_FACTOR_REQUEST})

        return fetchWithToken(
            `${API_BASE_URL}/auth/verify-totp-factor`,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(details)
            },
            dispatch,
            getState
        ).then(response => {
            if (response.status === 400) {
                response.json().then((data) => {
                    if (data.errors) {
                        dispatch({
                            type: types.TOTP_VERIFY_FACTOR_RESPONSE_ERRORS,
                            errors: data.errors
                        })
                    } else {
                        dispatch(
                            {
                                type: types.TOTP_VERIFY_FACTOR_RESPONSE_ERRORS,
                                errors: {
                                    unknownError: "There was an unexpected error"
                                }
                            }
                        )
                    }
                })
            } else {
                return response.json()
            }
        }).then(data => {
            if (data.errors) {
                dispatch(
                    {
                        type: types.TOTP_VERIFY_FACTOR_RESPONSE_ERRORS,
                        errors: data.errors
                    }
                )
            } else {
                dispatch({
                    type: types.TOTP_VERIFY_FACTOR_RESPONSE_SUCCESS,
                    details: data
                })
            }
        })
        .catch((response) => {
            dispatch(
                {
                    type: types.TOTP_VERIFY_FACTOR_RESPONSE_ERRORS,
                    errors: {
                        unknownError: "There was an unexpected error"
                    }
                }
            )
        })
    }
}

export function resetTotpFactor() {
    return dispatch => {
        dispatch({type: types.TOTP_FACTOR_RESET })
    }
}

export function loginlessRequest(history, username) {
    return dispatch => {
        return fetch(API_BASE_URL +  '/auth/loginless', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                'username' : username
            })
        })
        .then(response => response.json())
        .then((payload) => {
            dispatch(({ type: types.LOGIN_RESPONSE })) // prevents spinner
            dispatch(loginlessResponse(history, payload))
        })
        .catch((response) => {
            dispatch(({ type: types.LOGIN_RESPONSE })) // prevents spinner
            history.push('/login')
        })
    }
}

export function loginlessResponse(history, payload) {

    return dispatch => {
        if (payload.access_token) {
            dispatch( {type: types.LOGIN_SUCCESS, auth: payload} )
            if (payload.redirect) {
                history.push(payload.redirect)
            } else {
                history.push('/viewer')
            }
        } else {
            history.push('/login')
        }
    }
}

export function refreshAccessTokenRequest(refreshToken) {

    return dispatch => {

        dispatch( { type: types.REFRESH_ACCESS_TOKEN_REQUEST, auth: { refreshToken: refreshToken } })

        return fetch(API_BASE_URL +  '/auth/authorize', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                'grant_type' : 'refresh_token',
                'application': 'viewer',
                'refresh_token' : refreshToken
            })
        })
        .then(response => {

            if (!response.ok) {
                // If here, we have errors
                dispatch( { type: types.REFRESH_ACCESS_TOKEN_ERROR})
            } else {

                // Might be No Content...especially on success
                response.json().then(data => {

                    if (data.errors) {
                        dispatch(refreshAccessTokenResponse(data))
                    } else {
                        dispatch(refreshAccessTokenResponse(data))
                    }

                })
            }
        })
        .catch((response) => {
            dispatch(loginErrors(response))
            dispatch(log("error", response))
        })
    };
}

export function refreshAccessTokenResponse(json) {
    return { type: types.REFRESH_ACCESS_TOKEN_RESPONSE, auth: json }
}

export function inheritAuthCredentials(auth, history) {
    return (dispatch, getState) => {
        dispatch({type: types.VIEWER_RESET_USER})
        dispatch({type: types.INHERIT_AUTH_CREDENTIALS, auth: auth})
        dispatch({type: types.VIEWER_LOAD_USER_SUCCESS, user: { mfa: { authAs: true} }})
        if ('cameraIndex' in auth) {
            history.push(`/viewer/projects/0/cameras/${auth.cameraIndex}/image`)
        } else {
            history.push('/viewer')
        }
    }
}

export function logoutRequest(history) {

    return (dispatch, getState) => {

        dispatch( { type: types.LOGOUT_REQUEST })

        return fetchWithToken(
            API_BASE_URL +  '/auth/logout',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    'action' : 'logout'
                })
            },
            dispatch,
            getState
        )
        .then(response => response.json())
        .then((json) => {
            dispatch(logoutResponse(history, json))
        })
        .catch((response) => {
            dispatch(loginErrors(response))
            dispatch(log("error", response))
        })

    };
}

export function logoutResponse(history, response) {
    return dispatch => {
        history.push('/login')
        dispatch({type: types.LOGOUT_RESPONSE, auth: response })
    }
}

export function checkUserLockdownStatus() {

    return (dispatch, getState) => {

        dispatch({ type: types.CHECK_USER_LOCKDOWN_STATUS_REQUEST })

        return fetchWithToken(
            API_BASE_URL + '/api/status/lockdown',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {

            if (!response.ok) {
                // If here, we have errors
                dispatch({type: types.CHECK_USER_LOCKDOWN_STATUS_RESPONSE, status: "ON"})
            } else {

                // Might be No Content...especially on success
                response.json().then(data => {

                    if (data.errors) {
                        dispatch({type: types.CHECK_USER_LOCKDOWN_STATUS_RESPONSE, status: "ON"})
                    } else {
                        dispatch({type: types.CHECK_USER_LOCKDOWN_STATUS_RESPONSE, status: data.status})
                    }

                })
            }
        })
        .catch((response) => {
            dispatch(loginErrors(response))
            dispatch(log("error", response))
        })

    }
}

export function resetLoginCounter() {
    return { type: types.RESET_LOGIN_COUNTER }
}
