import moment from 'moment'

import * as types from './actionTypes'
import { API_BASE_URL } from '../config'
import { fetchWithToken } from '../util/accessToken'
import { loadWeather } from './weatherActions'
import { setCameraDates } from './userActions'
import { selectImage } from './currentImageActions'
import { selectDate as compareSelectDate } from './compareActions'
import { cameraModelAssociation } from './compareActions'
import { notAuthorisedResponse } from './authActions'

export function loadDatesForAllCameras(projects, history) {
    return (dispatch, getState) => {
        let promises = []
        dispatch({type: types.VIEWER_LOAD_CAMERA_DATES_REQUEST})
        projects.forEach((project, p) => {
            project.cameras.forEach((camera, c) => {
                const url = `${API_BASE_URL}/api/viewer/projects/${p}/cameras/${c}/dates`
                promises.push(loadCameraDates(url, dispatch, getState, history)
                .then((data) => {
                    if (!data || data.errors || !data.start || !data.end) {
                        // TODO:: Anything here?

                    } else {
                        dispatch(setCameraDates(p, c, data))
                        // TODO:: Get latest thumbnail here??
                    }
                }).catch((response) => {
                    // TODO:: Do something to handle this??
                }))
            })
        })
        return Promise.all(promises).then(() => {
            // If no date was set in the route, use the latest date for the
            // given camera
            const state = getState()
            let date = state.camera.selectedDate
            let project = state.navigation.currentProject
            if (project === undefined) {
                project = 0
            }
            let camera = state.navigation.currentCamera
            if (camera === undefined) {
                camera = 0
            }
            if (state.user.projects[project]) {
                // Check associations to see if there is a model for this camera
                dispatch(cameraModelAssociation(state.user.projects[project], camera))
                // Set compare date and time if enabled
                let compare = state.compare
                if (compare.enabled) {
                    if (compare.selectedDate) {
                        dispatch(compareSelectDate(compare.selectedDate, compare.selectedTime))
                    }
                }
                const endDate = (state.user.projects[project].cameras[camera])? state.user.projects[project].cameras[camera].end : null
                if (endDate) {
                    if (date === undefined) {
                        date = endDate
                    }
                    dispatch({type: types.VIEWER_LOAD_CAMERA_DATES_RESPONSE})
                    return dispatch(selectDate(date, history))
                } else {
                    dispatch({
                        type: types.VIEWER_LOAD_CAMERA_DATES_ERROR,
                        errors: 'Unable to load any dates for this camera'
                    })
                }
            }

        })
    }
}

export function loadCameraDates(url, dispatch, getState, history) {
    return fetchWithToken(
        url,
        {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        },
        dispatch,
        getState
    )
    .then(response => {
        if (response.status === 401) {
            dispatch(notAuthorisedResponse())
        } else if (response.status === 204) {
            const error = {
                errors: {
                    noImagesErrorType: {
                        noImagesError: "There are no dates for this camera with any images"
                    }
                }
            }
            throw(error)
        } else {
            return response.json()
        }
    })
    .then(data => {
        if (data.errors) {
            throw(data)
        } else {
            return data
        }
    })
    .catch((response) => {
        if (response !== undefined) {
            return response
        } else {
            return {
                type: types.VIEWER_LOAD_CAMERA_DATES_ERROR,
                errors: {
                    unknownErrorType: {
                        unknownError: "There was an unexpected error"
                    }
                }
            }
        }
    })
}

export function reloadCurrentCameraDates(history) {
  return (dispatch, getState) => {
    const state = getState();
    const projectId = state.navigation.currentProject;
    const cameraId = state.navigation.currentCamera;
    const project = state.user.projects[projectId];
    let camera;
    let isMedia = false;
    if (project) {
      camera = project.cameras[cameraId];
      if (camera && camera.type === 'Media') {
        isMedia = true;
      }
    }
    let url;
    if (isMedia) {
      url = `${API_BASE_URL}/api/viewer/projects/${projectId}`
                +`/cameras/${cameraId}/media/dates`;
    } else {
      url = `${API_BASE_URL}/api/viewer/projects/${projectId}`
                +`/cameras/${cameraId}/dates`;
    }
    return fetchWithToken(
        url,
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
        },
        dispatch,
        getState
    )
        .then((response) => {
          if (response.status === 401) {
            dispatch(notAuthorisedResponse())
          } else if (response.status === 204) {
            const error = {
              errors: {
                noImagesErrorType: {
                  noImagesError: 'There are no dates for this camera with any images',
                },
              },
            };
            throw (error);
          } else {
            return response.json();
          }
        })
        .then((data) => {
          if (data.errors) {
            throw (data);
          } else {
            return dispatch(setCameraDates(projectId, cameraId, data));
          }
        })
        .catch((response) => {
          if (response !== undefined) {
            return response;
          } else {
            dispatch({
                type: types.VIEWER_LOAD_CAMERA_DATES_ERROR,
              errors: {
                unknownErrorType: {
                  unknownError: 'There was an unexpected error',
                },
              },
            });
          }
        });
  };
}

export function loadImageList(date, history) {
    return (dispatch, getState) => {
        dispatch({ type: types.VIEWER_LOAD_IMAGE_LIST_REQUEST })
        const state = getState()
        const proj = state.navigation.currentProject
        const cam = state.navigation.currentCamera
        if (date === undefined) {
            if (state.camera.selectedDate) {
                date = state.camera.selectedDate
            } else {
                // No date set, use latest for camera
                date = state.user.projects[proj].cameras[cam].end
            }
        }
        let errors = false
        return fetchWithToken(
            `${API_BASE_URL}/api/viewer/projects/${proj}/cameras/${cam}/images/${date}`,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (response.status === 401) {
                dispatch(notAuthorisedResponse())
                return
            } else if (response.status === 204) {
                dispatch(
                    {
                        type: types.VIEWER_LOAD_IMAGE_LIST_ERROR,
                        errors: {
                            noDatesErrorType: {
                                noDatesError: "There are no images to display for this date."
                            }
                        }
                    }
                )
                return
            }
            if (!response.ok) {
                errors = true
            }
            return response
        })
        .then(response => response.json())
        .then(data => {
            if (data.errors) {
                dispatch(
                    {
                        type: types.VIEWER_LOAD_IMAGE_LIST_ERROR,
                        errors: data.errors
                    }
                )
            } else if (errors) {
                dispatch(
                    {
                        type: types.VIEWER_LOAD_IMAGE_LIST_ERROR,
                        errors: {
                            unknownErrorType: {
                                unknownError: "There was an unexpected error"
                            }
                        }
                    }
                )
            } else {
                dispatch(
                    {
                        type: types.VIEWER_LOAD_IMAGE_LIST_RESPONSE,
                        images: data
                    }
                )
                // If no image set, use latest
                // or if midnight, use first
                const state = getState()
                let time = state.camera.selectedTime
                if (time === undefined) {
                    const latestImage = data[data.length-1]
                    if (latestImage) {
                        time = latestImage.time
                    }
                } else if (time === '00:00') {
                    const firstImage = data[0]
                    time = firstImage.time
                }
                dispatch(selectTime(time))
            }
        })
        .catch((response) => {
            dispatch(
                {
                    type: types.VIEWER_LOAD_IMAGE_LIST_ERROR,
                    errors: {
                        unknownErrorType: {
                            unknownError: "There was an unexpected error"
                        }
                    }
                }
            )
        })
    }
}

export function selectDate(date, history) {
    return (dispatch, getState) => {
        const state = getState()
        const currentProject = state.navigation.currentProject
        const currentCamera = state.navigation.currentCamera

        if (!currentProject === undefined || currentCamera === undefined) {
            return
        }
        const project = state.user.projects[currentProject]
        // Don't do anything yet if user details haven't loaded
        if (!project) {
            dispatch({ type: types.VIEWER_SELECT_CAMERA_DATE, date: date })
            return
        }
        const camera = state.user.projects[currentProject].cameras[currentCamera]
        // Don't do anything if camera dates are not yet loaded
        if (!camera || !camera.start) {
            return
        }
        if (date === undefined) {
            date = camera.end
        }
        if (date === 'first') {
            date = camera.start
        }
        let newDate = moment(date, 'YYYY-MM-DD')
        // Check if date is valid
        const start = moment(camera.start, 'YYYY-MM-DD')
        const end = moment(camera.end, 'YYYY-MM-DD')
        const missing = camera.missing
        if (newDate < start ) {
            date = camera.start
        } else if (newDate > end) {
            date = camera.end
        } else if (missing.indexOf(date) > -1) {
            const oldDate = moment(state.camera.selectedDate)
            if (!oldDate.isValid() || oldDate > newDate) {
                // Find the previous date that isn't missing
                do {
                    newDate = newDate.subtract(1, 'days')
                } while (missing.indexOf(newDate.format('YYYY-MM-DD')) !== -1)
                if (newDate < start) {
                    newDate = start
                }
            } else {
                // Find the next date that isn't missing
                do {
                    newDate = newDate.add(1, 'days')
                } while (missing.indexOf(newDate.format('YYYY-MM-DD')) !== -1)
                if (newDate > end) {
                    newDate = end
                }
            }
            date = newDate.format('YYYY-MM-DD')
        }
        dispatch({ type: types.VIEWER_SELECT_CAMERA_DATE, date: date })
        dispatch(loadWeather(date))
        if (state.compare.enabled && !state.compare.selectedDate) {
            dispatch(compareSelectDate(date))
        }

        return dispatch(loadImageList(undefined, history))
    }
}

export function selectTime(time) {
    return (dispatch, getState) => {
        const state = getState()
        const imageList = state.camera.imageList
        if (imageList.images.length > 0) {
            let image = getSelectedImageFromList(imageList.images, time)
            if (!image) {
                // No matching image was found, find the closest
                image = findClosestImageToTime(imageList.images, time)
            }
            try {
                time = image.time
            } catch(err) {
                // unable to determine a time, abandon
                return
            }
            const project = state.navigation.currentProject
            const camera = state.navigation.currentCamera
            
            dispatch(selectImage(project, camera, image))
        }
        dispatch({
            type: types.VIEWER_SELECT_CAMERA_TIME,
            time: time
        })
    }
}

export function clearImageList() {
    return {
        type: types.VIEWER_CLEAR_IMAGE_LIST
    }
}

export function setAssociatedCamera(camera) {
    return {
        type: types.VIEWER_SET_ASSOCIATED_CAMERA,
        camera: camera
    }
}

export function setCameraIsModel(isModel) {
    return {
        type: types.VIEWER_SET_IS_MODEL_STATE,
        isModel: isModel
    }
}

export function getSelectedImageFromList (images, time)  {
    if (time === 'first') {
        return images[0]
    }
    for (var i=0; i < images.length; i++) {
        const image = images[i]
        if (image && time === image.time) {
            return image
        }
        if (image && time === image.image) {
            return image
        }
    }
}

export function findClosestImageToTime(images, time) {
    const searchTime = moment(time, 'HH:mm')
    let previousDiff = 0
    for (var i in images) {
        const image = images[i];
        const compareTime = moment(image['time'], 'HH:mm')
        const diff = Math.abs(compareTime - searchTime)
        if (searchTime < compareTime) {
            if (previousDiff === 0) {
                return image
            }
            if (diff < previousDiff) {
                return image
            } else {
                return images[i-1]
            }
        }
        previousDiff = diff
    }
    return images[images.length-1]
}
