import * as types from './actionTypes'

const PAN_WINDOW_HIDE_TIMEOUT = 2000

export function showPanWindow(delay) {
    return (dispatch, getState) => {
        dispatch({
            type: types.VIEWER_PHOTO_SHOW_PAN_WINDOW
        })
        if (delay) {
            dispatch(hidePanWindow(true))
        }
    }
}

export function hidePanWindow(delay) {
    return (dispatch, getState) => {
        if (!delay) {
            dispatch({
                type: types.VIEWER_PHOTO_HIDE_PAN_WINDOW
            })
        } else {
            if (window.hidePanWindowTimeout) {
                clearTimeout(window.hidePanWindowTimeout)
            }
            window.hidePanWindowTimeout = setTimeout(
                () => {
                    dispatch({
                        type: types.VIEWER_PHOTO_HIDE_PAN_WINDOW
                    })
                },
                PAN_WINDOW_HIDE_TIMEOUT
            )
        }
    }
}

export function togglePanWindow() {
  return (dispatch, getState) => {
    const {panWindow} = getState();
    if (panWindow.panWindowVisible) {
      dispatch({
        type: types.VIEWER_PHOTO_ALLOW_HIDE_PAN_WINDOW,
      });
      dispatch({
        type: types.VIEWER_PHOTO_HIDE_PAN_WINDOW,
      });
    } else {
      dispatch({
        type: types.VIEWER_PHOTO_PREVENT_HIDE_PAN_WINDOW,
      });
      dispatch({
        type: types.VIEWER_PHOTO_SHOW_PAN_WINDOW,
      });
    }
  };
}

export function preventHidePanWindow() {
    return {
        type: types.VIEWER_PHOTO_PREVENT_HIDE_PAN_WINDOW
    }
}

export function allowHidePanWindow() {
    return {
        type: types.VIEWER_PHOTO_ALLOW_HIDE_PAN_WINDOW
    }
}
