import initialState from './initialState'
import {
    VIEWER_SELECT_COMPARE_IMAGE,
    VIEWER_ASSOCIATED_CAMERA_LOAD_DATES_REQUEST,
    VIEWER_CLEAR_COMPARE_IMAGE,
    VIEWER_SELECT_PROJECT_AND_CAMERA,
    LOGIN_RESPONSE
} from '../actions/actionTypes'

export default function compareImage(state = initialState.compareImage, action) {
    switch (action.type) {
        case VIEWER_SELECT_COMPARE_IMAGE:
            return Object.assign(
                {},
                state,
                {
                    width: action.width,
                    height: action.height,
                    time: action.time,
                    date: action.date,
                    filename: action.image.image,
                    imageURL: action.image_url,
                    thumbnailURL: action.thumbnail_url
                }
            )
        case VIEWER_ASSOCIATED_CAMERA_LOAD_DATES_REQUEST:
        case VIEWER_CLEAR_COMPARE_IMAGE:
        case VIEWER_SELECT_PROJECT_AND_CAMERA:
        case LOGIN_RESPONSE:
            return initialState.compareImage
        default:
            return state
    }
}
