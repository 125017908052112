import initialState from './initialState'
import {
     VIEWER_ASSOCIATED_CAMERA_LOAD_DATES_REQUEST,
     VIEWER_ASSOCIATED_CAMERA_LOAD_DATES_RESPONSE,
     VIEWER_ASSOCIATED_CAMERA_LOAD_DATES_ERROR,
     VIEWER_ASSOCIATED_CAMERA_LOAD_IMAGE_LIST_REQUEST,
     VIEWER_ASSOCIATED_CAMERA_LOAD_IMAGE_LIST_RESPONSE,
     VIEWER_ASSOCIATED_CAMERA_LOAD_IMAGE_LIST_ERROR,
     VIEWER_ASSOCIATED_CAMERA_CLEAR_IMAGE_LIST,
     VIEWER_ASSOCIATED_CAMERA_RESET,
     LOGIN_RESPONSE
} from '../actions/actionTypes'

export default function associatedCamera(state = initialState.associatedCamera, action) {

    switch (action.type) {
        case VIEWER_ASSOCIATED_CAMERA_LOAD_DATES_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    dates: {
                        loading: true,
                        success: false,
                        errors: {},
                        start: state.dates.start,
                        end: state.dates.end,
                        missing: state.dates.missing
                    }
                }
            )
        case VIEWER_ASSOCIATED_CAMERA_LOAD_DATES_RESPONSE:
            return Object.assign(
                {},
                state,
                {
                    dates: {
                        loading: false,
                        success: true,
                        errors: {},
                        start: action.start,
                        end: action.end,
                        missing: action.missing
                    }
                }
            )
        case VIEWER_ASSOCIATED_CAMERA_LOAD_DATES_ERROR:
            return Object.assign(
                {},
                state,
                {
                    dates: {
                        loading: false,
                        success: false,
                        errors: action.errors,
                        start: null,
                        end: null,
                        missing: []
                    },
                    imageList: {
                        loading: state.imageList.loading,
                        success: state.imageList.success,
                        errors: state.imageList.errors,
                        images: []
                    }
                }
            )
        case VIEWER_ASSOCIATED_CAMERA_LOAD_IMAGE_LIST_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    imageList: {
                        loading: true,
                        success: false,
                        errors: {},
                        images: state.imageList.images
                    }
                }
            )
        case VIEWER_ASSOCIATED_CAMERA_LOAD_IMAGE_LIST_RESPONSE:
            return Object.assign(
                {},
                state,
                {
                    imageList: {
                        loading: false,
                        success: true,
                        errors: {},
                        images: action.images
                    }
                }
            )
        case VIEWER_ASSOCIATED_CAMERA_LOAD_IMAGE_LIST_ERROR:
            return Object.assign(
                {},
                state,
                {
                    imageList: {
                        loading: false,
                        success: false,
                        errors: action.errors,
                        images: []
                    }
                }
            )
        case VIEWER_ASSOCIATED_CAMERA_CLEAR_IMAGE_LIST:
            return Object.assign(
                {},
                state,
                {
                    imageList: initialState.associatedCamera.imageList
                }
            )
        case VIEWER_ASSOCIATED_CAMERA_RESET:
            return initialState.associatedCamera
        case LOGIN_RESPONSE:
            return initialState.associatedCamera
        default:
            return state
    }
}
