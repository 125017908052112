import initialState from './initialState'
import {
    VIEWER_LOAD_USER_REQUEST,
    VIEWER_LOAD_USER_SUCCESS,
    VIEWER_LOAD_USER_ERROR,
    VIEWER_USER_SUSPENDED,
    VIEWER_SET_CAMERA_DATES,
    VIEWER_LOAD_PROJECT_THUMBS_REQUEST,
    VIEWER_LOAD_PROJECT_THUMBS_SUCCESS,
    VIEWER_LOAD_PROJECT_THUMBS_ERROR,
    VIEWER_RESET_USER,
    VIEWER_SHOW_TRACKING_CONSENT,
    VIEWER_HIDE_TRACKING_CONSENT,
    LOGIN_RESPONSE
} from '../actions/actionTypes'

export default function user(state = initialState.user, action) {

    switch (action.type) {
        case VIEWER_LOAD_USER_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    loading: true,
                    success: false,
                    errors: {},
                }
            )
        case VIEWER_LOAD_USER_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    loading: false,
                    success: true,
                    errors: {},
                    projectsLoaded: true,
                    ...action.user
                }
            )
        case VIEWER_LOAD_USER_ERROR:
            return Object.assign(
                {},
                state,
                {
                    loading: false,
                    success: false,
                    errors: action.errors
                }
            )
        case VIEWER_USER_SUSPENDED:
            return Object.assign(
                {},
                state,
                {
                    suspendedMessage: action.message
                }
            )
        case VIEWER_SET_CAMERA_DATES:
            let projects = state.projects
            projects[action.project].cameras[action.camera].start = action.start
            projects[action.project].cameras[action.camera].end = action.end
            projects[action.project].cameras[action.camera].missing = action.missing
            return Object.assign(
                {},
                state,
                {
                    projects: projects,
                    projectsLoaded: true,
                }
            )
        case LOGIN_RESPONSE:
            return initialState.user
        case VIEWER_RESET_USER:
            return initialState.user
        case VIEWER_LOAD_PROJECT_THUMBS_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    loading: true,
                    success: false,
                    errors: {},
                }
            )
        case VIEWER_LOAD_PROJECT_THUMBS_SUCCESS:
            let data = state
            for (var p = 0; p < action.thumbnails.length; p++) {
                const project = action.thumbnails[p]
                for (var c = 0; c < project.length; c++) {
                    if (data['projects'][p] !== undefined) {
                        try {
                            Object.assign(
                                data['projects'][p]['cameras'][c],
                                project[c]
                            )
                        } catch(exception) {
                        }
                    }
                }
            }
            return Object.assign(
                {},
                data,
                {
                    loading: false,
                    success: true,
                    errors: {},
                }
            )
        case VIEWER_LOAD_PROJECT_THUMBS_ERROR:
            return Object.assign(
                {},
                state,
                {
                    loading: false,
                    success: false,
                    errors: action.errors
                }
            )
        case VIEWER_SHOW_TRACKING_CONSENT:
            return Object.assign(
                {},
                state,
                {
                    showTrackingConsent: true
                }
            )
        case VIEWER_HIDE_TRACKING_CONSENT:
            return Object.assign(
                {},
                state,
                {
                    showTrackingConsent: false
                }
            )
        default:
            return state
    }
}
