import * as types from './actionTypes'
import { API_BASE_URL}  from '../config'

export function log(level, message) {

    return dispatch => {

        dispatch({type: types.LOG_REQUEST, level, message})

        return fetch(API_BASE_URL + '/api/log', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                level: level,
                message: message
            })
        })
        .catch((response) => {
            // Unable to send log
        })

    }
}
