import initialState from './initialState'
import {
    VIEWER_IMAGE_LOAD,
    VIEWER_PHOTO_UPDATE_WINDOW_DIMENSIONS,
    VIEWER_PHOTO_UPDATE_IMAGE_DIMENSIONS,
    VIEWER_PHOTO_SET_PAN_AND_ZOOM,
} from '../actions/actionTypes'

export default function photoDimensions(state = initialState.photoDimensions, action) {
    switch (action.type) {
        case VIEWER_IMAGE_LOAD:
            return Object.assign(
                {},
                state,
                action.dimensions
            )
        case VIEWER_PHOTO_UPDATE_WINDOW_DIMENSIONS:
            return Object.assign(
                {},
                state,
                action.dimensions
            )
        case VIEWER_PHOTO_UPDATE_IMAGE_DIMENSIONS:
            return Object.assign(
                {},
                state,
                action.dimensions
            )
        case VIEWER_PHOTO_SET_PAN_AND_ZOOM:
            return Object.assign(
                {},
                state,
                action.parameters
            )
        default:
            return state
    }
}
